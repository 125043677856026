import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  FaSave,
  FaEye,
  FaEyeSlash,
  FaKey,
  FaUserEdit,
  FaUserCircle,
} from "react-icons/fa";
import { useUser } from "../../context/UserContext";
import { useUsers } from "../../hooks";
import { useNotifications } from "../../context/NotificationContext";

const EditUser = () => {
  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { user } = useUser();
  const { fetchUserById, profile, setProfile, updateUser } = useUsers();

  useEffect(() => {
    fetchUserById(id);
  }, [id]);

  const handleSave = async (e) => {
    e.preventDefault();
    updateUser(id, profile, selectedFile);
    addNotification({ message: "Bruker oppdatert.", type: "success" });
    navigate(`/brukere/${id}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleGeneratePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomPassword = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      randomPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    setProfile((prevUser) => ({ ...prevUser, password: randomPassword }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 lg:p-8">
      {/* Updated the container to use full width and grid layout */}
      <div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-8">
        {/* Sidebar Section */}
        <div className="bg-white shadow-xl rounded-lg p-6 lg:col-span-3">
          <div className="flex flex-col items-center">
            {profile.picture ? (
              <img
                src={`${apiURL}/${profile.picture}`}
                alt="User Profile"
                className="w-32 h-32 rounded-full object-cover shadow-md mb-4"
              />
            ) : (
              <FaUserCircle className="text-gray-400 text-8xl mb-4" />
            )}
            <h3 className="text-xl font-bold text-gray-800 mb-1 text-center">
              {profile.name}
            </h3>
            <p className="text-green-600 text-md font-medium text-center">
              {profile.type === "worker" ? "Arbeider" : "Admin"}
            </p>
            <div className="mt-6 space-y-4 w-full">
              <div className="flex items-center justify-center">
                <FaUserEdit className="text-green-600 mr-2 text-lg" />
                <span className="text-gray-800 text-md font-medium text-center">
                  Redigerer profil
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content Section */}
        <div className="lg:col-span-9">
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="border-b pb-4 mb-6">
              <h2 className="text-2xl font-bold text-gray-800 flex items-center">
                <FaUserEdit className="text-green-600 mr-3" />
                Endre Bruker
              </h2>
            </div>
            <form className="space-y-6" onSubmit={handleSave}>
              {/* Profile Picture */}
              <div className="flex flex-col lg:flex-row items-center lg:space-x-6">
                {profile.picture ? (
                  <img
                    src={`${apiURL}/${profile.picture}`}
                    alt="User Profile"
                    className="w-32 h-32 rounded-full object-cover shadow-md mb-4 lg:mb-0"
                  />
                ) : (
                  <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center mb-4 lg:mb-0">
                    <FaUserEdit className="text-gray-400 text-6xl" />
                  </div>
                )}
                <div className="w-full">
                  <label
                    htmlFor="picture"
                    className="block text-lg font-medium text-gray-700"
                  >
                    Endre Profilbilde
                  </label>
                  <input
                    type="file"
                    id="picture"
                    name="picture"
                    onChange={handleFileChange}
                    className="mt-2 block w-full text-gray-700"
                  />
                </div>
              </div>

              {/* Name */}
              <div>
                <label
                  htmlFor="name"
                  className="block text-lg font-medium text-gray-700"
                >
                  Navn
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={profile.name || ""}
                  onChange={handleChange}
                  className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-600 focus:ring-green-600"
                  placeholder="Skriv navnet her"
                />
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-lg font-medium text-gray-700"
                >
                  E-post
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profile.email || ""}
                  onChange={handleChange}
                  className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-600 focus:ring-green-600"
                  placeholder="Skriv e-post her"
                />
              </div>

              {/* Password */}
              <div>
                <label
                  htmlFor="password"
                  className="block text-lg font-medium text-gray-700"
                >
                  Passord
                </label>
                <div className="relative mt-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={profile.password || ""}
                    onChange={handleChange}
                    className="block w-full pr-24 border-gray-300 rounded-md shadow-sm focus:border-green-600 focus:ring-green-600"
                    placeholder="Skriv et nytt passord her"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-16 px-3 flex items-center bg-gray-200 border border-l-0 border-gray-300 hover:bg-gray-300"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                  <button
                    type="button"
                    onClick={handleGeneratePassword}
                    className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-200 border border-l-0 border-gray-300 rounded-r-md hover:bg-gray-300"
                  >
                    <FaKey />
                  </button>
                </div>
              </div>

              {/* Only show these fields if the user is an admin */}
              {user.type === "admin" && (
                <>
                  {/* Type */}
                  <div>
                    <label
                      htmlFor="type"
                      className="block text-lg font-medium text-gray-700"
                    >
                      Type
                    </label>
                    <select
                      id="type"
                      name="type"
                      value={profile.type || ""}
                      onChange={handleChange}
                      className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-600 focus:ring-green-600"
                    >
                      <option value="worker">Arbeider</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>

                  {/* Active */}
                  <div>
                    <label
                      htmlFor="active"
                      className="block text-lg font-medium text-gray-700"
                    >
                      Aktiv
                    </label>
                    <select
                      id="active"
                      name="active"
                      value={profile.active || ""}
                      onChange={handleChange}
                      className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-600 focus:ring-green-600"
                    >
                      <option value="1">Ja</option>
                      <option value="0">Nei</option>
                    </select>
                  </div>

                  {/* Level */}
                  <div>
                    <label
                      htmlFor="level"
                      className="block text-lg font-medium text-gray-700"
                    >
                      Nivå
                    </label>
                    <input
                      type="number"
                      id="level"
                      name="level"
                      value={profile.level || ""}
                      onChange={handleChange}
                      className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-600 focus:ring-green-600"
                      placeholder="Skriv bruker nivå her"
                    />
                  </div>
                </>
              )}

              {/* Save Button */}
              <div className="text-right">
                <button
                  type="submit"
                  className="inline-flex items-center bg-green-grave text-white py-3 px-6 rounded-lg shadow-md hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-600 transition duration-150 ease-in-out"
                >
                  <FaSave className="mr-2" />
                  Lagre Endringer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;