import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance'; // Ensure axiosInstance is configured correctly
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../context/NotificationContext';

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [profile, setProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  // Fetch all users with query parameters
  const fetchUsers = useCallback(async ({ search, sort, order, page, limit, active }) => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams({ search, sort, order, page, limit, active }).toString();
      const response = await axiosInstance.get(`/api/users?${queryParams}`);
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch a single user by ID
  const fetchUserById = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/users/${id}`);
      setProfile(response.data);
    } catch (err) {
      console.error('Error fetching user profile:', err);
      setError('Kunne ikke hente brukerprofil');
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Add a new user
  const addUser = async (userInfo, selectedFile) => {
    const formData = new FormData();
    formData.append('name', userInfo.name);
    formData.append('type', userInfo.type);
    formData.append('email', userInfo.email);
    formData.append('password', userInfo.password);
    formData.append('active', userInfo.active);
    formData.append('level', userInfo.level);
    if (selectedFile) formData.append('picture', selectedFile);

    try {
      const response = await axiosInstance.post('/api/users', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      addNotification({ message: 'Bruker opprettet.', type: 'success' });
      navigate('/users');
    } catch (err) {
      console.error('Error creating user:', err);
      addNotification({ message: err.message || 'En feil oppstod.', type: 'error' });
      setError('Kunne ikke opprette bruker');
    }
  };

  // Update an existing user by ID
  const updateUser = async (id, userInfo, selectedFile) => {
    const formData = new FormData();
    formData.append('name', userInfo.name);
    formData.append('type', userInfo.type);
    formData.append('email', userInfo.email);
    if (userInfo.password) formData.append('password', userInfo.password);
    formData.append('active', userInfo.active);
    formData.append('level', userInfo.level);
    if (selectedFile) formData.append('picture', selectedFile);

    try {
      await axiosInstance.put(`/api/users/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      addNotification({ message: 'Bruker oppdatert.', type: 'success' });
    } catch (err) {
      console.error('Error updating user:', err);
      addNotification({ message: err.message || 'En feil oppstod.', type: 'error' });
      setError('Kunne ikke oppdatere bruker');
    }
  };

  const getUserOptions = useCallback(() => {
    return users.map((user) => ({
      value: user.id.toString(),
      label: user.name,
    }));
  }, [users]);

  return {
    users,
    profile,
    setProfile, // Expose setUser here
    getUserOptions,
    isLoading,
    error,
    totalPages,
    fetchUsers,
    fetchUserById,
    addUser,
    updateUser,
  };
};