import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaKey, FaUserPlus } from 'react-icons/fa';
import { useNotifications } from '../../context/NotificationContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const CreateUser = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const [showPassword, setShowPassword] = useState(false);

  // Define validation schema using Yup
  const schema = yup.object().shape({
    name: yup.string().required('Navn er påkrevd'),
    type: yup.string().oneOf(['worker', 'admin']).required('Type er påkrevd'),
    email: yup.string().email('Ugyldig e-postadresse').required('E-post er påkrevd'),
    password: yup
      .string()
      .required('Passord er påkrevd')
      .min(8, 'Passordet må være minst 8 tegn langt'),
    active: yup.boolean().required('Aktiv status er påkrevd'),
    level: yup.number().required('Nivå er påkrevd').min(1, 'Nivå må være minst 1'),
    picture: yup.mixed(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      type: 'worker',
      email: '',
      password: '',
      active: true,
      level: 1,
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleGeneratePassword = () => {
    const length = 12;
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]\\:;?><,./-=';
    let randomPassword = '';
    for (let i = 0; i < length; ++i) {
      randomPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setValue('password', randomPassword);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'picture' && data.picture[0]) {
        formData.append(key, data.picture[0]);
      } else {
        formData.append(key, data[key]);
      }
    });

    try {
      await axios.post(`${apiURL}/api/users`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      addNotification({ message: 'Bruker opprettet.', type: 'success' });
      navigate('/users');
    } catch (error) {
      console.error(error);
      addNotification({
        message: error.response?.data?.message || 'En feil oppstod',
        type: 'error',
      });
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-8 bg-white rounded-xl shadow-lg mt-10">
      <div className="flex items-center mb-6">
        <FaUserPlus className="text-green-500 text-3xl mr-3" />
        <h1 className="text-4xl font-bold text-gray-800">Opprett Ny Bruker</h1>
      </div>
      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        {/* User Information Section */}
        <div className="bg-gray-50 p-6 rounded-lg shadow-inner">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">Brukerinformasjon</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Name */}
            <div>
              <label htmlFor="name" className="block text-lg font-medium text-gray-700">
                Navn
              </label>
              <input
                type="text"
                id="name"
                {...register('name')}
                className={`mt-2 block w-full border ${
                  errors.name ? 'border-red-500' : 'border-gray-300'
                } rounded-md shadow-sm focus:border-green-500 focus:ring-green-500`}
                placeholder="Skriv navnet her"
              />
              {errors.name && <p className="text-red-500 mt-1">{errors.name.message}</p>}
            </div>
            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-lg font-medium text-gray-700">
                E-post
              </label>
              <input
                type="email"
                id="email"
                {...register('email')}
                className={`mt-2 block w-full border ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                } rounded-md shadow-sm focus:border-green-500 focus:ring-green-500`}
                placeholder="Skriv e-post her"
              />
              {errors.email && <p className="text-red-500 mt-1">{errors.email.message}</p>}
            </div>
            {/* Password */}
            <div className="md:col-span-2">
              <label htmlFor="password" className="block text-lg font-medium text-gray-700">
                Passord
              </label>
              <div className="relative mt-2">
  <div className="flex rounded-md shadow-sm">
    <input
      type={showPassword ? 'text' : 'password'}
      id="password"
      {...register('password')}
      className={`flex-1 block w-full min-w-0 border ${
        errors.password ? 'border-red-500' : 'border-gray-300'
      } rounded-l-md focus:border-green-500 focus:ring-green-500`}
      placeholder="Skriv et nytt passord her"
    />
    <button
      type="button"
      onClick={togglePasswordVisibility}
      className="px-3 flex items-center bg-gray-200 border border-l-0 border-gray-300 hover:bg-gray-300"
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </button>
    <button
      type="button"
      onClick={handleGeneratePassword}
      className="px-3 flex items-center bg-gray-200 border border-l-0 border-gray-300 rounded-r-md hover:bg-gray-300"
    >
      <FaKey />
    </button>
  </div>
  {errors.password && (
    <p className="text-red-500 mt-1">{errors.password.message}</p>
  )}
</div>
              {errors.password && (
                <p className="text-red-500 mt-1">{errors.password.message}</p>
              )}
            </div>
          </div>
        </div>

        {/* Account Settings Section */}
        <div className="bg-gray-50 p-6 rounded-lg shadow-inner">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">Kontoinnstillinger</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Type */}
            <div>
              <label htmlFor="type" className="block text-lg font-medium text-gray-700">
                Type
              </label>
              <select
                id="type"
                {...register('type')}
                className={`mt-2 block w-full border ${
                  errors.type ? 'border-red-500' : 'border-gray-300'
                } rounded-md shadow-sm focus:border-green-500 focus:ring-green-500`}
              >
                <option value="worker">Worker</option>
                <option value="admin">Admin</option>
              </select>
              {errors.type && <p className="text-red-500 mt-1">{errors.type.message}</p>}
            </div>
            {/* Active */}
            <div>
              <label htmlFor="active" className="block text-lg font-medium text-gray-700">
                Aktiv
              </label>
              <select
                id="active"
                {...register('active')}
                className={`mt-2 block w-full border ${
                  errors.active ? 'border-red-500' : 'border-gray-300'
                } rounded-md shadow-sm focus:border-green-500 focus:ring-green-500`}
              >
                <option value={true}>Ja</option>
                <option value={false}>Nei</option>
              </select>
              {errors.active && <p className="text-red-500 mt-1">{errors.active.message}</p>}
            </div>
            {/* Level */}
            <div>
              <label htmlFor="level" className="block text-lg font-medium text-gray-700">
                Nivå
              </label>
              <input
                type="number"
                id="level"
                {...register('level')}
                className={`mt-2 block w-full border ${
                  errors.level ? 'border-red-500' : 'border-gray-300'
                } rounded-md shadow-sm focus:border-green-500 focus:ring-green-500`}
                placeholder="Skriv bruker nivå her"
              />
              {errors.level && <p className="text-red-500 mt-1">{errors.level.message}</p>}
            </div>
            {/* Picture */}
            <div>
              <label htmlFor="picture" className="block text-lg font-medium text-gray-700">
                Profilbilde
              </label>
              <input
                type="file"
                id="picture"
                {...register('picture')}
                className="mt-2 block w-full text-gray-700"
              />
              {errors.picture && <p className="text-red-500 mt-1">{errors.picture.message}</p>}
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-right">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`inline-flex items-center bg-green-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-150 ease-in-out ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isSubmitting ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Oppretter...
              </>
            ) : (
              'Opprett Bruker'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUser;