import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNotifications } from "../../context/NotificationContext";
import { AiOutlineCamera } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa"; // Spinner icon for upload indicator
import BackButton from "../../components/BackButton";
import { useLogs } from "../../hooks/useLogs"; // Import useLogs hook
import { useUser } from "../../context/UserContext";

const requestGeolocation = (setGeolocationAccuracy) => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error("Geolocation is not supported by your browser"));
      return;
    }

    // Request the position with higher timeout and fallback to lower accuracy
    navigator.geolocation.getCurrentPosition(
      resolve,
      (error) => {
        if (error.code === error.TIMEOUT) {
          console.warn("Geolocation timed out. Retrying with lower accuracy.");
          setGeolocationAccuracy("low"); // Update state to indicate lower accuracy

          // Retry with lower accuracy
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: false, // Retry with lower accuracy
            timeout: 15000, // Increase the timeout
            maximumAge: 0,
          });
        } else {
          reject(error); // Handle other geolocation errors (denied, unavailable, etc.)
        }
      },
      {
        enableHighAccuracy: true, // Try high accuracy first
        timeout: 7500, // Initial timeout
        maximumAge: 0,
      }
    );
  });
};

const VisitEditPage = () => {
  const { visitId } = useParams();
  const [visitDetails, setVisitDetails] = useState({
    visitDetails: {
      before_pictures: null,
      after_pictures: null,
    },
  });
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [location, setLocation] = useState(null);
  const [beforeImagePreview, setBeforeImagePreview] = useState(null);
  const [afterImagePreview, setAfterImagePreview] = useState(null);
  const [beforeImageId, setBeforeImageId] = useState(null);
  const [afterImageId, setAfterImageId] = useState(null);

  const [uploadingImageType, setUploadingImageType] = useState(null); // State to track which image is uploading
  const [geolocationAccuracy, setGeolocationAccuracy] = useState("high"); // State to track geolocation accuracy

  const { addNotification } = useNotifications();
  const { createLog } = useLogs();
  const { user } = useUser();

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    fetch(`${apiURL}/api/visits/${visitId}`)
      .then((response) => response.json())
      .then((data) => {
        setVisitDetails(data);
        setComment(data.visitDetails.description);
        setSelectedActivities(data.selectedActivities.map((a) => a.id));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching visit details:", error);
        setError("Failed to load visit details");
        setLoading(false);
      });
  }, [visitId]);

  const toggleActivitySelection = (activityId) => {
    setSelectedActivities((current) =>
      current.includes(activityId)
        ? current.filter((id) => id !== activityId)
        : [...current, activityId]
    );
  };

  const handleCapture = async (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploadingImageType(type); // Set which image is uploading

    try {
      const position = await requestGeolocation(setGeolocationAccuracy); // Pass the setter to track geolocation accuracy
      setLocation(position.coords);

      const formData = new FormData();
      formData.append("image", file);
      formData.append("latitude", position.coords.latitude);
      formData.append("longitude", position.coords.longitude);
      formData.append("gravestoneId", visitDetails.visitDetails.gravestone_id);
      formData.append("type", type);
      formData.append("visitId", visitId);

      const response = await fetch(`${apiURL}/api/pictures/upload`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (!response.ok)
        throw new Error(result.message || "Failed to upload image");

      if (type === "before") {
        setBeforeImagePreview(URL.createObjectURL(file));
        setBeforeImageId(result.id);
        createLog({
          type: "action",
          message: `Før-bilde lastet opp for besøk ${visitId}`,
          userId: user.id,
        });
      } else if (type === "after") {
        setAfterImagePreview(URL.createObjectURL(file));
        setAfterImageId(result.id);
        createLog({
          type: "action",
          message: `Etter-bilde lastet opp for besøk ${visitId}`,
          userId: user.id,
        });
      }
    } catch (error) {
      console.error("Error capturing the image or fetching location:", error);
    } finally {
      setUploadingImageType(null); // End uploading
      setGeolocationAccuracy("high"); // Reset accuracy
    }
  };

  const handleSave = async () => {
    const requestBody = {
      visitId,
      selectedActivities,
      comment,
      beforeImageId,
      afterImageId,
    };

    try {
      const response = await fetch(`${apiURL}/api/visit/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      addNotification({ message: "Besøk oppdatert", type: "success" });

      if (afterImageId) {
        createLog({
          type: "action",
          message: `Besøk ${visitId} er ferdigstilt`,
          userId: user.id,
        });
      }

      console.log("Visit updated successfully:", responseData);
    } catch (error) {
      addNotification({
        message: "Kunne ikke oppdatere besøket.",
        type: "error",
      });
      createLog({
        type: "error",
        message: `Kunne ikke oppdatere besøk ${visitId}. Error: ${error}`,
        userId: user.id,
      });
      console.error("Error saving visit details:", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!visitDetails) return <div>No visit details found</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Besøk</h1>

      <div className="mb-4">
        <h2 className="text-lg font-semibold">Aktiviteter:</h2>
        <div className="flex flex-wrap gap-2">
          {visitDetails.allActivities.map((activity) => (
            <button
              key={activity.id}
              className={`px-4 py-2 rounded-full text-sm ${
                selectedActivities.includes(activity.id)
                  ? "bg-green-grave text-white"
                  : "bg-gray-200 text-gray-800"
              } ${
                visitDetails.plannedActivities.find((a) => a.id === activity.id)
                  ? "ring-2 ring-orange-grave"
                  : ""
              }`}
              onClick={() => toggleActivitySelection(activity.id)}
            >
              {activity.name}
            </button>
          ))}
        </div>
      </div>

      <div className="mb-4">
        <label
          htmlFor="comment"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Kommentar:
        </label>
        <textarea
          id="comment"
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
          placeholder="Legg til kommentar.."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        {["before", "after"].map((type) => (
          <div
            key={type}
            className="border p-4 flex justify-center items-center relative"
          >
            <label
              htmlFor={`capture${type}`}
              className="cursor-pointer w-full h-full flex justify-center items-center relative"
            >
              {(type === "before" &&
                (beforeImagePreview ||
                  visitDetails.visitDetails.before_picture_url)) ||
              (type === "after" &&
                (afterImagePreview ||
                  visitDetails.visitDetails.after_picture_url)) ? (
                <img
                  src={
                    type === "before"
                      ? beforeImagePreview ||
                        apiURL + visitDetails.visitDetails.before_picture_url
                      : afterImagePreview ||
                        apiURL + visitDetails.visitDetails.after_picture_url
                  }
                  alt={type}
                  className="max-w-full max-h-40 object-cover w-full h-full"
                />
              ) : (
                <AiOutlineCamera size={48} />
              )}

              {/* Show spinner or geolocation status only for the image being uploaded */}
              {uploadingImageType === type && (
                <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                  <FaSpinner className="text-white animate-spin" size={24} />
                  <span className="ml-2 text-white">Laster opp...</span>
                </div>
              )}

              {uploadingImageType === type && geolocationAccuracy === "low" && (
                <div className="absolute top-0 left-0 bg-yellow-400 text-black px-2 py-1 text-xs">
                  Lav nøyaktighet
                </div>
              )}

              <input
                type="file"
                id={`capture${type}`}
                accept="image/*"
                capture="environment"
                onChange={(e) => handleCapture(e, type)}
                className="hidden"
              />
            </label>
          </div>
        ))}
      </div>

      <button
        className="px-4 py-2 bg-green-grave text-white rounded hover:bg-green-700"
        onClick={handleSave}
      >
        Lagre
      </button>
    </div>
  );
};

export default VisitEditPage;