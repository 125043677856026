import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import Rating from './Rating';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';

const VisitCommentsTable = ({ comments, onCommentClick }) => {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = (visitId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [visitId]: !prev[visitId],
    }));
  };

  // Group comments by visit_id and visit_type
  const groupedComments = comments.reduce((acc, comment) => {
    const key = `${comment.visit_id}-${comment.visit_type}`;
    if (!acc[key]) acc[key] = [];
    acc[key].push(comment);
    return acc;
  }, {});

  // Sort each group by created_at to ensure the original comment is first
  Object.keys(groupedComments).forEach((key) => {
    groupedComments[key].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  });

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow rounded-lg">
        <thead className="bg-green-grave text-white">
          <tr>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Dato</th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Kommentar</th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Kunde</th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Gravpersoner</th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Besøk</th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Vurdering</th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Besøkt</th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Sett</th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">Svar</th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {Object.entries(groupedComments).map(([key, group], rowIndex) => {
            const originalComment = group[0]; // The first comment in the sorted group is the original
            const replies = group.slice(1); // All subsequent comments are considered replies
            const isExpanded = expandedRows[originalComment.visit_id];

            return (
              <React.Fragment key={key}>
                <tr
                  className={`hover:bg-gray-100 ${rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                  onClick={() => onCommentClick(originalComment)}
                >
                  <td className="px-4 py-3 text-sm">
                    {new Date(originalComment.created_at).toLocaleString('nb-NO')}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {originalComment.comment}
                    {replies.length > 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the modal on expand/collapse click
                          toggleRowExpansion(originalComment.visit_id);
                        }}
                        className="ml-2 text-green-grave hover:text-orange-grave"
                      >
                        {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <Link
                      to={`/kunder/${originalComment.customer_id}`}
                      className="text-green-grave hover:text-orange-grave underline"
                    >
                      {originalComment.customer_name}
                    </Link>
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {/* Display the gravepersons connected to the gravestone */}
                    {originalComment.gravepersons?.length > 0 ? (
                      <div className="space-y-2">
                        {originalComment.gravepersons.map((person) => (
                          <div key={person.id} className="text-sm">
                            <Link
                              to={`/gravsteiner/${originalComment.gravestone_id}`}
                              className="font-semibold text-green-grave hover:text-orange-grave underline"
                            >
                              {person.name}
                            </Link>
                            <div className="text-gray-600 text-xs">
                              {person.birthdate ? new Date(person.birthdate).toLocaleDateString('nb-NO') : '??'} -{' '}
                              {person.deathdate ? new Date(person.deathdate).toLocaleDateString('nb-NO') : '??'}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span className="text-gray-500">Ingen gravpersoner</span>
                    )}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <Link
                      to={`/besok/${originalComment.visit_id}/detaljer/${
                        originalComment.visit_type === 'visits' ? '1' : '0'
                      }`}
                      className="text-green-grave underline hover:text-orange-grave"
                    >
                      {originalComment.visit_id}
                    </Link>
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <Rating
                      visit_type={originalComment.visit_type}
                      visit_id={originalComment.visit_id}
                      gravestone_id={originalComment.gravestone_id}
                      customer_id={originalComment.customer_id}
                      readOnly={true}
                    />
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {new Date(originalComment.visit_date).toLocaleString('nb-NO')}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {originalComment.seen_by_user ? (
                      <AiOutlineLike className="text-green-grave text-xl" />
                    ) : (
                      <AiOutlineDislike className="text-red-500 text-xl" />
                    )}
                  </td>
                  <td className="px-4 py-3 text-md">
                    {replies.length > 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the modal on expand/collapse click
                          toggleRowExpansion(originalComment.visit_id);
                        }}
                        className="text-green-grave hover:text-orange-grave underline"
                      >
                        {isExpanded ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    )}
                  </td>
                </tr>
                {isExpanded &&
                  replies.map((reply, replyIndex) => (
                    <tr
                      key={reply.id}
                      className={`hover:bg-gray-100 ${
                        (rowIndex + replyIndex + 1) % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                      }`}
                      onClick={() => onCommentClick(reply)}
                    >
                      <td className="px-4 py-3 text-sm">
                        {new Date(reply.created_at).toLocaleString('nb-NO')}
                      </td>
                      <td className="px-4 py-3 text-sm" colSpan="4">
                        {reply.comment}
                      </td>
                      <td className="px-4 py-3 text-sm" colSpan="2">
                        {reply.user_name ? (
                          <Link
                            to={`/brukere/${reply.user_id}`}
                            className="text-green-grave hover:text-orange-grave underline"
                          >
                            {reply.user_name}
                          </Link>
                        ) : (
                          <Link
                            to={`/kunder/${reply.customer_id}`}
                            className="text-green-grave hover:text-orange-grave underline"
                          >
                            {reply.customer_name}
                          </Link>
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm">
                        {reply.user_name ? (
                          reply.seen_by_customer ? (
                            <AiOutlineLike className="text-green-grave text-xl" />
                          ) : (
                            <AiOutlineDislike className="text-red-500 text-xl" />
                          )
                        ) : reply.seen_by_user ? (
                          <AiOutlineLike className="text-green-grave text-xl" />
                        ) : (
                          <AiOutlineDislike className="text-red-500 text-xl" />
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm"></td>
                    </tr>
                  ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VisitCommentsTable;