import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { NotificationProvider } from "./context/NotificationContext";
import Notification from "./components/Notifications";
import { BrowserRouter as Router } from "react-router-dom";
import { SidebarProvider } from "./context/SidebarContext";
import AppContent from "./AppContent";

function App() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDGOwg0cldad_rLB7pXWfCJpK6-sQe_qsU", // Replace with your API key
  });

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-green-500 to-cyan-600">
        <div className="text-center p-4 bg-white shadow-xl rounded-lg">
          <div className="flex items-center justify-center">
            <svg
              className="animate-spin h-12 w-12 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="12" y1="2" x2="12" y2="6"></line>
              <line x1="12" y1="18" x2="12" y2="22"></line>
              <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
              <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
              <line x1="2" y1="12" x2="6" y2="12"></line>
              <line x1="18" y1="12" x2="22" y2="12"></line>
              <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
              <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
          </div>
          <h2 className="text-xl font-semibold text-green-600 mt-4">
            Vennligst vent...
          </h2>
          <p className="text-green-500">Laster nødvendige data</p>
        </div>
      </div>
    );
  }

  return (
    <NotificationProvider>
      <Notification />
      <SidebarProvider>
        <Router>
          <AppContent />
        </Router>
      </SidebarProvider>
    </NotificationProvider>
  );
}

export default App;
