import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { useNotifications } from "../context/NotificationContext";
import { useLogs } from "../hooks/useLogs";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login, logout } = useUser();
  const { addNotification } = useNotifications();
  const { createLog } = useLogs();
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const from = location.state?.from?.pathname || "/";

  const { user } = useUser();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${apiURL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || "An error occurred");
        setIsLoading(false);
        return;
      }

      // Store tokens and user info in localStorage
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);

      // Calculate the expiration time for the token
      const accessTokenExpiration = new Date().getTime() + 15 * 60 * 1000; // 15 minutes
      localStorage.setItem("accessTokenExpiration", accessTokenExpiration);

      // Save user data to context
      login({ id: data.userId, name: data.userName, type: data.userType });

      // Start session timeout watcher
      startSessionTimeout(accessTokenExpiration);

      // Create login log
      createLog({ userId: data.userId, type: "login", message: "Logget inn" });

      addNotification({ message: "Logget inn.", type: "success" });
      navigate(from, { replace: true });
    } catch (err) {
      setError("Failed to login");
      setIsLoading(false);
    }
  };

  const startSessionTimeout = (expirationTime) => {
    const timeoutDuration = expirationTime - new Date().getTime();

    // Schedule auto-logout after token expiration
    setTimeout(() => {
      // Attempt to refresh token
      refreshToken();
    }, timeoutDuration - 1 * 60 * 1000); // Trigger 1 minute before expiration
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    try {
      const response = await fetch(`${apiURL}/token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: refreshToken }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Failed to refresh token");

      // Update tokens in localStorage
      localStorage.setItem("accessToken", data.accessToken);
      const newExpirationTime = new Date().getTime() + 15 * 60 * 1000;
      localStorage.setItem("accessTokenExpiration", newExpirationTime);

      // Restart the session timeout watcher
      startSessionTimeout(newExpirationTime);
    } catch (err) {
      // On refresh failure, log out the user
      handleLogout();
    }
  };

  const handleLogout = () => {
    logout(); // Clear user from context
    localStorage.clear(); // Clear tokens and user info from localStorage
    addNotification({
      message: "Session expired. You have been logged out.",
      type: "warning",
    });

    // Create logout log
    createLog({ userId: user.id, type: "logout", message: "Logget uten grunnet timeout" });

    navigate("/login");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-500 to-cyan-600">
      <div className="max-w-lg w-full space-y-8 p-10 bg-white rounded-xl shadow-lg">
        <div className="flex justify-center">
          <img
            className="h-12 w-auto"
            src="/images/logo_login.png"
            alt="Gravtellerne Logo"
          />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Logg Inn
        </h2>
        {error && (
          <p className="text-center text-red-500 text-sm italic">{error}</p>
        )}
        <form onSubmit={handleLogin} className="mt-8 space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              E-post
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Passord
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            >
              Logg inn
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;