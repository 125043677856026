import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Topbar from '../../components/Topbar';
import Toolbar from '../../components/Toolbar';
import ReusableTable from '../../components/ReusableTable';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import ReusableSearch from '../../components/ReusableSearch';
import ReusableLimit from '../../components/ReusableLimit';
import PageLayout from '../../layouts/PageLayout';
import ReusableHeaderV2 from '../../components/ReusableHeaderV2';
import { FaUserEdit, FaUserSlash, FaUserFriends } from 'react-icons/fa';

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('id');
  const [order, setOrder] = useState('ASC');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(0);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);

  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams({ search, sort, order, page, limit }).toString();
        const response = await fetch(`${apiURL}/api/customers?${queryParams}`);
        if (!response.ok) {
          throw new Error('Something went wrong!');
        }
        const data = await response.json();
        setCustomers(data.customers);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomers();
  }, [search, sort, order, page, limit]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  const handleRowRightClick = (row) => {
    return [
      { label: 'Endre', icon: FaUserEdit, onClick: () => alert(`Edit Customer ${row.first_name} ${row.last_name}`) },
      { label: 'Slett', icon: FaUserSlash, onClick: () => alert(`Delete Customer ${row.first_name} ${row.last_name}`) },
    ];
  };

  const columns = [
    { label: 'Fornavn', accessor: 'first_name', link: (row) => `/kunder/${row.id}`, width: '150px' },
    { label: 'Etternavn', accessor: 'last_name', width: '150px' },
    { label: 'E-post', accessor: 'email', width: '250px' },
    { label: 'Kundenummer', accessor: 'customer_number', width: '150px' },
    { label: 'Avdeling', accessor: 'department_name', width: '150px' },
    { label: 'Telefon', accessor: 'phone_number', width: '150px' },
    { label: 'Siste kommentar', accessor: 'latest_comment', width: '300px' },
  ];

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Kunder" linkTo="/kunder/ny" icon={FaUserFriends} />
      <Topbar>
        <Toolbar>
        <ReusableLimit value={limit} onChange={handleLimitChange} />
        <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      {isLoading && <Spinner />}
      <ReusableTable
        columns={columns}
        data={customers}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        onRowRightClick={handleRowRightClick} // Pass the context menu handler
      />
      <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
    </PageLayout>
  );
}

export default Customers;