import React, { useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useNotifications } from '../../context/NotificationContext';
import { FaSave, FaTimes } from 'react-icons/fa';

const EditDepartmentModal = ({ department, onClose, onDepartmentUpdated }) => {
  const [editedDepartment, setEditedDepartment] = useState(department);
  const { addNotification } = useNotifications();

  const handleSave = async () => {
    try {
      await axiosInstance.put(`/api/departments/${department.id}`, editedDepartment);
      addNotification({ message: 'Avdelingen ble oppdatert', type: 'success' });
      onDepartmentUpdated();
    } catch (error) {
      console.error(error);
      addNotification({ message: error.message || 'An error occurred', type: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDepartment(prevDept => ({ ...prevDept, [name]: value }));
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-800">Endre Avdeling</h1>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </div>
        <form className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={editedDepartment.name || ''}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-grave focus:ring-green-grave"
              placeholder="Legg til navn"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-lg font-medium text-gray-700">Beskrivelse:</label>
            <textarea
              id="description"
              name="description"
              value={editedDepartment.description || ''}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-grave focus:ring-green-grave"
              placeholder="Legg til beskrivelse"
            ></textarea>
          </div>
          <div>
            <label htmlFor="active" className="block text-lg font-medium text-gray-700">Aktiv?</label>
            <select
              id="active"
              name="active"
              value={editedDepartment.active || 0}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-grave focus:ring-green-grave"
            >
              <option value={1}>Ja</option>
              <option value={0}>Nei</option>
            </select>
          </div>
        </form>
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-300"
          >
            Avbryt
          </button>
          <button
            onClick={handleSave}
            className="bg-green-grave text-white py-2 px-4 rounded-md hover:bg-orange-grave"
          >
            <FaSave className="inline mr-2" />
            Lagre
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditDepartmentModal;