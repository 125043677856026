import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSidebar } from "../context/SidebarContext";
import { useUser } from "../context/UserContext";
import requestGeolocation from "./Geolocation";
import MenuItem from "./MenuItem"; // Adjust the path as necessary
import MobileMenu from "./MobileMenu";
import NewFeaturesPopup from "./popups/NewFeaturesPopup";
import { FiMenu } from "react-icons/fi";
import axiosInstance from "../utils/axiosInstance";

// Move menuItems to a separate config file for better readability and maintenance
import menuItemsConfig from "../config/menuItemsConfig";

import SidebarProfile from "./SidebarProfile";

const Sidebar = () => {
  const { isOpen, toggleSidebar } = useSidebar();
  const { user } = useUser();
  const [hasUnseenComments, setHasUnseenComments] = useState(false); // State to track unseen comments

  // Use memoization for logoPath and profilePath
  const logoPath = useMemo(() => isOpen ? `${process.env.PUBLIC_URL}/images/logo.svg` : `${process.env.PUBLIC_URL}/logo192.png`, [isOpen]);
  const profilePath = useMemo(() => `/brukere/${user?.id}`, [user?.id]);

  // Memoized function to fetch unseen comments
  const fetchUnseenComments = useCallback(async () => {
    if (user) {
      try {
        const response = await axiosInstance.get("/api/visit_comments/unseen_by_user");
        setHasUnseenComments(response.data.unseen);
      } catch (error) {
        console.error("Error fetching unseen comments status:", error);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      requestGeolocation(); // Request geolocation only if the user is logged in
      fetchUnseenComments(); // Fetch unseen comments status
    }
  }, [user, fetchUnseenComments]);

  return (
    <>
      <div
        className={`hidden sm:block fixed left-0 top-0 bottom-0 z-30 transition-width duration-300 ease-in-out bg-gray-800 ${
          isOpen ? "w-52" : "w-20"
        }`}
      >
        <div className="flex flex-col justify-between h-full overflow-y-auto">
          <div>
            {/* Sidebar Toggle Button */}
            <div className="flex items-center justify-end p-4">
              <button
                onClick={toggleSidebar}
                className="absolute text-white top-3 text-2xl rounded-full hover:bg-green-grave focus:outline-none focus:ring-2 focus:ring-orange-grave focus:ring-opacity-50"
              >
                <FiMenu />
              </button>
            </div>

            {/* Sidebar Logo */}
            <div className="flex items-center justify-center p-4">
              <Link to="/">
                <img
                  src={logoPath}
                  alt="Logo"
                  className={`transition-transform duration-300 ease-in-out ${
                    isOpen ? "scale-100" : "scale-75"
                  }`}
                />
              </Link>
            </div>

            {/* Menu Items */}
            <ul className="flex-grow">
              {menuItemsConfig.map((item, index) => (
                <MenuItem
                  key={index}
                  icon={item.icon}
                  label={item.label}
                  to={item.to}
                  userType={item.userType}
                  isOpen={isOpen}
                  hasNotification={item.hasNotification && item.label === "Tilbakemeldinger" ? hasUnseenComments : item.hasNotification}
                  children={item.children} // Pass submenu items if they exist
                />
              ))}
            </ul>
          </div>

          {/* User Profile Section */}
          <div className="mt-auto p-2 text-white bg-gray-800">
            {user && <SidebarProfile user={user} isOpen={isOpen} profilePath={profilePath} />}
          </div>
        </div>
      </div>

      {/* Mobile Bottom Navigation Bar: Visible on sm and smaller screens */}
      <div className="sm:hidden">
        <React.Suspense fallback={<div>Loading...</div>}>
          <MobileMenu />
        </React.Suspense>
      </div>
    </>
  );
};

export default Sidebar;