// PlaceholderInserter.js
import React from "react";

/*
.replace(/{{customer_first_name}}/g, customer.first_name)
      .replace(/{{customer_last_name}}/g, customer.last_name)
      .replace(/{{customer_email}}/g, customer.email)
      .replace(/{{customer_phone_number}}/g, customer.phone_number)
      .replace(/{{customer_address}}/g, customer.address)
      .replace(/{{customer_password}}/g, `Kunde${customer.customer_number}`);
*/

const placeholders = [
  { label: "Fornavn (Kunde)", value: "{{customer_firstname}}" },
  { label: "Etternavn (Kunde)", value: "{{customer_lastname}}" },
  { label: "E-post (Kunde)", value: "{{customer_email}}" },
  { label: "Telefon (Kunde)", value: "{{customer_phone}}" },
  { label: "Adresse (Kunde)", value: "{{customer_address}}" },
  // Add more placeholders as needed
];

const PlaceholderInserter = ({ onInsert }) => {
  return (
    <div className="mb-4">
      <h3 className="text-sm font-medium text-gray-700 mb-2">
        Sett inn plassholdere
      </h3>
      <div className="flex space-x-2">
        {placeholders.map((placeholder) => (
          <button
            key={placeholder.value}
            type="button"
            className="bg-gray-200 px-3 py-1 rounded-md hover:bg-gray-300"
            onClick={() => onInsert(placeholder.value)}
          >
            {placeholder.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PlaceholderInserter;
