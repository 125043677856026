import React, { useState, useEffect } from "react";
import {
  FaUsers,
  FaChurch,
  FaMonument,
  FaTasks,
  FaChartLine,
  FaRegListAlt,
  FaStar,
} from "react-icons/fa";
import {FaGauge} from "react-icons/fa6";
import { useUser } from "../context/UserContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import StatBox from "../components/StatBox";
import LoadingScreen from "../components/LoadingScreen";
import PunchClock from "../components/PunchClock";
import PageLayout from "../layouts/PageLayout";
import ReusableHeaderV2 from "../components/ReusableHeaderV2";
import StaticRating from "../components/StaticRating";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [stats, setStats] = useState({
    customers: 0,
    graveyards: 0,
    gravestones: 0,
    workorders: 0,
  });
  const [workOrderCounts, setWorkOrderCounts] = useState([]);
  const [activeWorkorders, setActiveWorkorders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const { user } = useUser();

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/stats/count`);
        setStats(response.data);
        const workOrderResponse = await axios.get(
          `${apiURL}/api/stats/workorders-by-month`
        );
        setWorkOrderCounts(workOrderResponse.data);
        const activeWorkordersResponse = await axios.get(
          `${apiURL}/api/stats/active-workorders`
        );
        setActiveWorkorders(activeWorkordersResponse.data);
      } catch (err) {
        setError("Failed to fetch stats");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [user.id]);

  const chartData = {
    labels: workOrderCounts?.map(({ year, month }) => `${month}/${year}`),
    datasets: [
      {
        label: "Arbeidslister",
        data: workOrderCounts?.map(({ count }) => count),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Arbeidslister per måned",
      },
    },
    // Other options as needed
  };

  const RenderForAdmin = () => (
    <>
      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-1">
        <StatBox
          href="/kunder"
          bgColor="bg-green-grave"
          icon={<FaUsers className="text-4xl" />}
          count={stats.customers}
          label="Kunder"
        />
        <StatBox
          href="/kirkegarder"
          bgColor="bg-complimentary-1"
          icon={<FaChurch className="text-4xl" />}
          count={stats.graveyards}
          label="Kirkegårder"
        />
        <StatBox
          href="/gravsteiner"
          bgColor="bg-complimentary-2"
          icon={<FaMonument className="text-4xl" />}
          count={stats.gravestones}
          label="Gravplasser"
        />
        <StatBox
          href="/arbeidslister"
          bgColor="bg-complimentary-3"
          icon={<FaTasks className="text-4xl" />}
          count={stats.workorders}
          label="Arbeidsordre"
        />
        <StatBox
          href="/vurderinger"
          bgColor="bg-complimentary-4"
          icon={<FaStar className="text-4xl" />}
          label="Vurdering"
        >
          {/* Insert StaticRating component as a child */}
          <StaticRating
            visit_type="visits_new"
            visit_id={1} // You can replace this with dynamic values
            gravestone_id={1} // You can replace this with dynamic values
            customer_id={1} // You can replace this with dynamic values
          />
        </StatBox>
      </div>
      <div className="mt-1 grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="lg:col-span-2 bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:px-6 flex items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
              <FaChartLine className="mr-2 text-blue-500" /> Månedlig
              Arbeidsrapport
            </h3>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="h-64">
              {/* Insert Line Chart Here */}
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>

        <div className="lg:col-span-1 bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:px-6 flex items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
              <FaRegListAlt className="mr-2 text-green-500" /> Aktive
              Arbeidslister
            </h3>
          </div>
          <div className="px-4 py-5 sm:p-6 space-y-4 max-h-96 overflow-y-auto">
            {activeWorkorders.map((workorder) => (
              <div
                key={workorder.id}
                className="border-b last:border-b-0 pb-4 last:pb-0"
              >
                <h4 className="font-semibold">{workorder.name}</h4>
                <div className="flex justify-between items-center mt-1">
                  <span className="text-sm font-medium text-gray-700">
                    {workorder.finishedVisits}/{workorder.totalGravestones}{" "}
                    ferdig
                  </span>
                  <span className="text-sm font-medium text-gray-700">
                    {(
                      (workorder.finishedVisits / workorder.totalGravestones) *
                      100
                    ).toFixed(0)}
                    %
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-2">
                  <div
                    className="bg-green-grave h-2.5 rounded-full"
                    style={{
                      width: `${
                        (workorder.finishedVisits /
                          workorder.totalGravestones) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );

  const RenderForWorker = () => (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4">
        <PunchClock userId={user?.id} />
      </div>
    </>
  );

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error: {error}</p>;

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Dashboard" icon={FaGauge} />
      {user.type === "admin" ? <RenderForAdmin /> : <RenderForWorker />}
    </PageLayout>
  );
}

export default Dashboard;
