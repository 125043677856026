import React from 'react';

const Pagination = ({ totalPages, currentPage, setPage }) => {
    const getPageNumbers = () => {
        const totalNumbers = 5; // total page numbers to show excluding first, last, and ellipsis
        const siblingCount = 2; // number of page numbers to show on each side of the current page
        const totalPageNumbers = totalNumbers + 2; // including first and last pages

        if (totalPages <= totalPageNumbers) {
            // Return range [1..totalPages]
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 2);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages - 1);

        const shouldShowLeftEllipsis = leftSiblingIndex > 2;
        const shouldShowRightEllipsis = rightSiblingIndex < totalPages - 1;

        const pageNumbers = [1];

        if (shouldShowLeftEllipsis) {
            pageNumbers.push('...');
        } else {
            for (let i = 2; i < leftSiblingIndex; i++) {
                pageNumbers.push(i);
            }
        }

        for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
            pageNumbers.push(i);
        }

        if (shouldShowRightEllipsis) {
            pageNumbers.push('...');
        } else {
            for (let i = rightSiblingIndex + 1; i < totalPages; i++) {
                pageNumbers.push(i);
            }
        }

        pageNumbers.push(totalPages);

        return pageNumbers;
    };

    const pageNumbers = getPageNumbers();

    return (
        <div className="flex space-x-2 justify-center pt-4 pb-2">
            {pageNumbers.map((number, index) =>
                typeof number === 'number' ? (
                    <button
                        key={number}
                        className={`px-4 py-2 ${
                            number === currentPage
                                ? 'bg-orange-grave text-white'
                                : 'bg-green-grave text-white'
                        } rounded hover:bg-orange-grave transition-colors`}
                        onClick={() => setPage(number)}
                    >
                        {number}
                    </button>
                ) : (
                    <span key={`ellipsis-${index}`} className="px-2">
                        ...
                    </span>
                )
            )}
        </div>
    );
};

export default Pagination;