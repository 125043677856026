import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FaHome,
  FaBookOpen,
  FaMonument,
  FaUser,
  FaSignOutAlt,
  FaBell,
} from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';
import NewCommentsList from './NewCommentsList';
import { useCustomer } from '../../context/CustomerContext';

const menuItems = [
  { href: '/kunde', label: 'Hjem', Icon: FaHome },
  { href: '/kunde/gravsteiner', label: 'Dine Gravsteiner', Icon: FaMonument },
  { href: '/kunde/besok', label: 'Besøk', Icon: FaBookOpen },
  { href: '/kunde/profil', label: 'Din Profil', Icon: FaUser },
  { href: '/kunde/loggut', label: 'Logg ut', Icon: FaSignOutAlt },
];

const TopBar = ({ children }) => {
  const [portalNotice, setPortalNotice] = useState(null);
  const { customer } = useCustomer();
  const [hasUnseenComments, setHasUnseenComments] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const commentsRef = useRef(null);
  const location = useLocation();

  const fetchPortalNotice = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        '/api/site_settings/customer_portal_notice'
      );
      setPortalNotice(response.data.setting_value);
    } catch (error) {
      console.error('Error fetching portal notice:', error);
    }
  }, []);

  const fetchUnseenComments = useCallback(async () => {
    if (!customer?.id) return;
    try {
      const response = await axiosInstance.get(
        '/api/visit_comments/unseen_by_customer',
        {
          params: { customer_id: customer.id },
        }
      );
      setHasUnseenComments(response.data.unseen);
    } catch (error) {
      console.error('Error fetching unseen comments status:', error);
    }
  }, [customer?.id]);

  useEffect(() => {
    fetchPortalNotice();
    fetchUnseenComments();
  }, [fetchPortalNotice, fetchUnseenComments]);

  useEffect(() => {
    // Re-fetch unseen comments when the route changes
    fetchUnseenComments();
  }, [location.pathname, fetchUnseenComments]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        commentsRef.current &&
        !commentsRef.current.contains(event.target)
      ) {
        setShowComments(false);
      }
    };

    if (showComments) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showComments]);

  const toggleCommentsList = () => {
    setShowComments((prev) => !prev);
  };

  return (
    <div>
      <header className="bg-white shadow-lg border-green-grave">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 h-24 flex items-center">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <img
                src="/images/gravstellerne-logo-kundeportal.png"
                alt="Logo"
                className="h-12"
              />
            </div>
            <div className="flex space-x-4">
              {menuItems.map(({ href, label, Icon }) => (
                <Link
                  key={href}
                  to={href}
                  className="text-gray-700 text-md hover:text-green-grave transition p-2 rounded-lg hover:bg-gray-100 flex items-center space-x-2"
                >
                  <Icon />
                  <span>{label}</span>
                </Link>
              ))}
              <div className="relative">
                <button
                  onClick={toggleCommentsList}
                  className="relative text-gray-700 text-md hover:text-green-grave transition p-2 mt-1 rounded-lg hover:bg-gray-100 flex items-center space-x-2"
                  aria-label="Notifications"
                >
                  <FaBell />
                  {hasUnseenComments && (
                    <span className="absolute top-0 right-0 bg-red-600 h-3 w-3 rounded-full"></span>
                  )}
                </button>
                {showComments && (
                  <div
                    ref={commentsRef}
                    className="absolute right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg p-4 z-50"
                  >
                    <NewCommentsList customer_id={customer?.id} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
        {portalNotice?.visible && (
          <div
            className={`${portalNotice.color} text-lg font-bold text-white text-center py-2`}
          >
            {portalNotice.message}
          </div>
        )}
      </header>
      {children}
    </div>
  );
};

export default TopBar;