import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaBirthdayCake, FaCross, FaDownload, FaFacebook, FaInstagram } from 'react-icons/fa';
import axios from 'axios';
import Rating from '../../components/Rating'; // Adjust the path to your Rating component
import VisitComment from '../../components/VisitComment';
import noImage from '../../images/no_image.png';

const CustomerVisitPage = () => {
  const { visitId } = useParams();
  const [visitDetails, setVisitDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchVisitDetails = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/legacy-visits/${visitId}`);
        console.log(response.data);
        setVisitDetails(response.data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching visit details:', err);
        setIsLoading(false);
      }
    };

    fetchVisitDetails();
  }, [visitId, apiURL]);

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  if (!visitDetails) {
    return <div className="text-center text-red-500">Error loading visit details</div>;
  }

  const handleShareOnFacebook = (picturePath) => {
    const imageUrl = getPictureUrl(picturePath);
    
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imageUrl)}`;
    window.open(shareUrl, '_blank');
  };

  const getPictureUrl = (picturePath) => {
    if (!picturePath) return noImage;
    return `https://cms.gravstellerne.no${picturePath}`;
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = getPictureUrl(visitDetails.after_pictures);
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const activities = Array.isArray(visitDetails.activities) ? visitDetails.activities : [];
  const gravepersons = Array.isArray(visitDetails.gravepersons) ? visitDetails.gravepersons : [];

  return (
    <div className="bg-white shadow-lg rounded-2xl p-6 max-w-7xl w-full border-t-4 border-green-grave">
      <h1 className="text-2xl font-bold text-gray-800 mb-2 text-center">Stell av Gravsted</h1>
      <p className="text-center text-lg text-gray-600 mb-4">
        Besøksdato: {new Date(visitDetails.visit_date).toLocaleDateString('nb-NO')}
      </p>
      <div className="bg-white rounded-xl overflow-hidden flex flex-col md:flex-row">
        <div className="p-4 w-full md:w-1/2">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Gravsten Informasjon</h2>
          <div className="space-y-3">
            {gravepersons.map((person, index) => (
              <div key={index} className="p-3 bg-gray-50 rounded-lg shadow border border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800">{`${person.first_name} ${person.last_name}`}</h3>
                <div className="mt-2 flex flex-col space-y-1">
                  <div className="flex items-center space-x-2">
                    <FaBirthdayCake className="text-gray-800" />
                    <p className="text-gray-800 text-sm">{person.birthdate ? new Date(person.birthdate).toLocaleDateString('nb-NO') : 'Ukjent fødselsdato'}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaCross className="text-gray-800" />
                    <p className="text-gray-800 text-sm">{person.deathdate ? new Date(person.deathdate).toLocaleDateString('nb-NO') : 'Ukjent dødsdato'}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4 mt-6">Arbeid utført</h2>
          <div className="flex flex-wrap gap-2">
            {activities.map((activity, index) => (
              <span key={index} className="px-3 py-1 bg-green-grave text-white rounded-full shadow text-sm hover:bg-green-800 cursor-default transition duration-300">
                {activity}
              </span>
            ))}
          </div>
          <div className="mt-6">
  <h2 className="text-xl font-semibold text-gray-800 mb-4">Utført av</h2>
  <div className="flex items-center">
    <img
      src={visitDetails.worker_picture ? `https://dev.gravstellerne.no/backend/${visitDetails.worker_picture}` : "https://via.placeholder.com/100"}
      alt="Worker"
      className="w-20 h-20 rounded-full mr-4"
    />
    <span className="text-lg font-medium text-gray-800">
      {visitDetails.worker_name.replace(' (Worker)', '')}
    </span>
  </div>
</div>
          <div className="mt-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Vurdering av Besøk</h2>
            <Rating
              visit_id={visitDetails.id}
              visit_type="visits" // Specify visit_type, you can change it dynamically if needed
              gravestone_id={visitDetails.gravestone_id}
              customer_id={visitDetails.customer_id}
              readOnly={false} // Set to true if you want to make it read-only
            />
            <h2 className="text-xl font-semibold text-gray-800 mt-4 mb-4">Gi Oss Tilbakemelding</h2>
            <VisitComment
              visit_id={visitDetails.id}
              visit_type="visits" // Specify visit_type, you can change it dynamically if needed
              gravestone_id={visitDetails.gravestone_id}
              customer_id={visitDetails.customer_id}
              readOnly={false} // Set to true if you want to make it read-only
            />
          </div>
        </div>

        <div className="w-full md:w-1/2 p-4 flex flex-col items-center justify-start">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Bilde fra Besøket</h2>
          <img
            src={getPictureUrl(visitDetails.after_pictures)}
            alt="Etter besøket"
            className="rounded-lg shadow-lg max-w-full md:max-w-lg mb-4"
          />
          <div className="flex space-x-3">
            <button
              onClick={handleDownload}
              className="px-3 py-1 bg-green-grave text-white rounded-full shadow text-sm hover:bg-green-800 flex items-center transition duration-300"
            >
              <FaDownload className="mr-2" />
              Last ned bilde
            </button>
            <button
              onClick={() => handleShareOnFacebook(visitDetails.after_pictures)}
              className="px-3 py-1 bg-blue-600 text-white rounded-full shadow text-sm hover:bg-blue-800 flex items-center transition duration-300"
            >
              <FaFacebook className="mr-2" />
              Del på Facebook
            </button>
          </div>
        </div>
      </div>
      <div className="text-center mt-6">
        <img src="/images/gravstellerne-logo.png" alt="Gravstellerne Logo" className="mx-auto max-w-xs" />
      </div>
    </div>
  );
};

export default CustomerVisitPage;