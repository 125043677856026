import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Topbar from '../../components/Topbar';
import ReusableHeader from '../../components/ReusableHeader';
import PageLayout from '../../layouts/PageLayout';
import ReusableHeaderV2 from '../../components/ReusableHeaderV2';
import SmsLogs from './SmsLogs'; // Import the SmsLogs component
import EmailLogs from './EmailLogs'; // Import the EmailLogs component
import Worklogs from '../worklogs/Worklogs';
import { FaSms, FaEnvelopeOpenText } from 'react-icons/fa'; // Icons for tabs
import { FaBarsStaggered, FaTableList } from "react-icons/fa6";

// Tab configuration for Logs with icons (similar to the Settings component)
const tabConfig = [
  { key: 'sms', label: 'SMS', icon: FaSms, component: SmsLogs },
  { key: 'epost', label: 'E-post', icon: FaEnvelopeOpenText, component: EmailLogs },
  { key: 'timer', label: 'Timer', icon: FaBarsStaggered, component: Worklogs},
];

const Logs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeTab = searchParams.get('tab') || 'sms'; // Default tab is 'sms'

  useEffect(() => {
    // Default to "sms" if no tab is specified in the URL or if the tab is invalid
    if (!tabConfig.find((tab) => tab.key === activeTab)) {
      navigate('/logs?tab=sms', { replace: true });
    }
  }, [activeTab, navigate]);

  const handleTabChange = (tabKey) => {
    // Update the query parameter when a new tab is selected
    setSearchParams({ tab: tabKey });
  };

  const renderTabContent = () => {
    const activeTabConfig = tabConfig.find((tab) => tab.key === activeTab);
    if (activeTabConfig) {
      const ActiveComponent = activeTabConfig.component;
      return <ActiveComponent />;
    }
    return <SmsLogs />; // Default to SMS Logs if tab is invalid
  };

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Logger" icon={FaTableList} />
      <div className="tabs flex space-x-4 px-6">
        {tabConfig.map((tab) => (
          <button
            key={tab.key}
            onClick={() => handleTabChange(tab.key)}
            className={`tab ${
              activeTab === tab.key
                ? 'bg-green-grave text-white'
                : 'bg-gray-200 text-gray-700'
            } flex items-center space-x-2 p-2 rounded-lg transition-all duration-300`}
          >
            <tab.icon className="text-xl" />
            <span className="text-lg">{tab.label}</span>
          </button>
        ))}
      </div>
      <div className="tab-content flex-1 p-8 bg-white shadow-md m-6 rounded-lg">
        {renderTabContent()}
      </div>
    </PageLayout>
  );
};

export default Logs;