import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import useInitiateVisit from "../../hooks/useInitiateVisit";
import ImageModal from "../../components/ImageModal";
import Modal from "../../components/ReusableModal";
import GravestoneMap from "../../components/GravestoneMap";
import GravestoneMapAll from "../../components/GravestoneMapAll";
import LoadingScreen from "../../components/LoadingScreen";
import BackButton from "../../components/BackButton";
import { getDistance } from 'geolib';
import { set } from 'date-fns';
import {
  AiOutlineCalendar,
  AiOutlineUser,
  AiOutlinePicture,
  AiOutlineFieldTime,
  AiOutlineGlobal,
} from "react-icons/ai";
import { MdLocationOff } from "react-icons/md";

const getValidGravestonesWithDistance = (gravestones, userLocation) => {
  // Ensure gravestones is always an array
  const gravestonesArray = Array.isArray(gravestones) ? gravestones : [];

  if (!userLocation || userLocation.latitude == null || userLocation.longitude == null) {
    console.error('User location is not available.');
    return { finishedGravestones: [], remainingGravestones: gravestonesArray };
  }

  const results = gravestonesArray.reduce((acc, gravestone) => {
    let distance = Number.MAX_VALUE; // Default high distance for invalid locations

    if (gravestone.latitude !== 'NULL' && gravestone.longitude !== 'NULL') {
      const gravestoneLocation = {
        latitude: parseFloat(gravestone.latitude),
        longitude: parseFloat(gravestone.longitude),
      };
      distance = getDistance(userLocation, gravestoneLocation);
    }

    const gravestoneWithDistance = { ...gravestone, distance };

    // Separate finished and remaining gravestones
    if (Number(gravestone.visitStatusId) === 3) {
      acc.finishedGravestones.push(gravestoneWithDistance);
    } else {
      acc.remainingGravestones.push(gravestoneWithDistance);
    }

    return acc;
  }, { finishedGravestones: [], remainingGravestones: [] });

  // No need to sort the entire results object; sort remainingGravestones by distance
  results.remainingGravestones.sort((a, b) => a.distance - b.distance);

  return results;
};

const WorkOrderDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [workorder, setWorkorder] = useState(null);
  const [gravestones, setGravestones] = useState([]); // Add this line
  const [finishedGravestones, setFinishedGravestones] = useState([]); // Add this line
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [activeTab, setActiveTab] = useState('remaining');
  const initiateVisit = useInitiateVisit();

  const [uniqueGraveyardPictures, setUniqueGraveyardPictures] = useState([]);

  const { user } = useUser();

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  const baseURL = "https://cms.gravstellerne.no";

  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    // Watch position changes
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => console.error(error),
      { enableHighAccuracy: true, maximumAge: 10000, timeout: 5000 }
    );
  
    // Clean up function to stop watching position when component unmounts or re-renders
    return () => navigator.geolocation.clearWatch(watchId);
  }, []);
  

  useEffect(() => {
    const fetchWorkOrderDetails = async () => {
      try {
        const response = await fetch(`${apiURL}/api/workorders/${id}`);
        const data = await response.json();
        if (!response.ok) throw new Error(data.message || "Could not fetch workorder details");
        
        setWorkorder(data);
  
        const { finishedGravestones, remainingGravestones } = getValidGravestonesWithDistance(data.gravestone_details, userLocation);
        
        setGravestones(remainingGravestones);
        setFinishedGravestones(finishedGravestones);
  
        // Check if remainingGravestones is empty and switch to finished tab
        if (remainingGravestones.length === 0) {
          setActiveTab('finished');
        }
  
        // Extract unique graveyard pictures
        const graveyardPictures = data.gravestone_details.map(item => item.graveyard_picture).filter(Boolean); // Get valid picture URLs
        const uniquePictures = [...new Set(graveyardPictures)]; // Get unique pictures
        setUniqueGraveyardPictures(uniquePictures); // Store unique pictures in state
  
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchWorkOrderDetails();
  }, [id, userLocation, apiURL]);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "1":
        return "text-green-500"; // Green for "Ready"
      case "2":
        return "text-yellow-500"; // Yellow for "In Progress"
      case "3":
        return "text-blue-500"; // Blue for "Completed"
      default:
        return "text-gray-500"; // Gray for unknown or other statuses
    }
  };

  const toggleMapModal = () => setIsMapModalOpen(!isMapModalOpen);

  if (loading) return <LoadingScreen />;
  if (error) return <div>Error: {error}</div>;
  if (!workorder) return <div>No workorder found</div>;

  const displayedGravestones =
  activeTab === 'remaining'
    ? gravestones
    : activeTab === 'finished'
    ? finishedGravestones
    : [];

return (
  <div className="container mx-auto p-4 mt-8 sm:mt-0">
    <h1 className="text-2xl font-bold mb-4">Arbeidsordre #{workorder.id}</h1>
    <div className="mb-6">
      <h2 className="text-xl mb-2">{workorder.name}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <p className="flex items-center">
          <AiOutlineCalendar className="mr-2" />
          Startdato:{" "}
          {new Date(workorder.work_date).toLocaleDateString("nb-NO")}
        </p>
        <p className="flex items-center">
          <AiOutlineCalendar className="mr-2" />
          Frist:{" "}
          {workorder.due_date
            ? new Date(workorder.due_date).toLocaleDateString("nb-NO")
            : "N/A"}
        </p>
        <p className="flex items-center col-span-2">
          <AiOutlineUser className="mr-2" />
          Arbeidere: {workorder.user_names}
        </p>
      </div>
    </div>

    {/* Tab Navigation */}
    <div className="flex justify-between items-center mb-3">
      <div className="flex space-x-4">
        <button
          className={`font-semibold ${activeTab === 'remaining' ? 'text-green-600' : 'text-gray-500'}`}
          onClick={() => switchTab('remaining')}
        >
          Gravsteiner
        </button>
        <button
          className={`font-semibold ${activeTab === 'finished' ? 'text-green-600' : 'text-gray-500'}`}
          onClick={() => switchTab('finished')}
        >
          Ferdige Gravsteiner
        </button>
        <button
          className={`font-semibold ${activeTab === 'map' ? 'text-green-600' : 'text-gray-500'}`}
          onClick={() => switchTab('map')}
        >
          Gravstedskart
        </button>
      </div>
      <button
        onClick={toggleMapModal}
        className="p-2 rounded-full hover:bg-gray-200 transition-colors"
        aria-label="Show Map"
      >
        <AiOutlineGlobal size="1.5em" />
      </button>
    </div>

    {/* Display based on active tab */}
    {activeTab === 'map' ? (
  <div className="p-4">
    <h3 className="text-lg font-bold mb-4">Gravstedskart</h3>
    {uniqueGraveyardPictures.map((picture, index) => (
      <img
        key={index}
        src={`https://cms.gravstellerne.no${picture}`}
        alt={`Gravstedskart ${index + 1}`}
        className="w-full h-auto rounded shadow-lg mb-4" // Adjust margin for multiple images
      />
    ))}
  </div>
) : (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 p-4">
        {displayedGravestones
          // Sorting logic remains the same
          .sort((a, b) => {
            if (Number(a.visitStatusId) === 2 && Number(b.visitStatusId) !== 2) return -1;
            if (Number(b.visitStatusId) === 2 && Number(a.visitStatusId) !== 2) return 1;
            return 0;
          })
          .map((gravestone, index) => (
            <div
              key={index}
              className="bg-white rounded-xl overflow-hidden shadow-lg transition duration-500"
            >
              <div
                className="relative cursor-pointer"
                onClick={() => openModal(apiURL + gravestone.thumbnail)}
              >
                <img
                  src={gravestone.thumbnail && gravestone.thumbnail !== "NULL"
                    ? apiURL + gravestone.thumbnail
                    : "/images/default_grave.jpg"}
                  alt="Gravestone"
                  className="w-full h-56 object-cover opacity-75"
                />
                {(!gravestone.latitude || !gravestone.longitude || gravestone.latitude === "NULL" || gravestone.longitude === "NULL") && (
                  <div className="absolute top-0 left-0 m-4 flex items-center text-white bg-black bg-opacity-50 rounded px-2 py-1">
                    <MdLocationOff className="text-lg mr-2" />
                    <p className="text-sm">Vises ikke på kart</p>
                  </div>
                )}
                <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black via-transparent text-white p-4">
                  <p className="text-xl font-semibold">{gravestone.gravepersonsNames}</p>
                  <div className="flex items-center justify-between">
                    <p className={`mt-2 px-3 py-1 inline-block rounded-full text-sm font-medium ${getStatusColor(gravestone.visitStatusId)}`}>
                      {gravestone.visitStatus}
                    </p>
                    <p className="mt-2 text-sm">
                      {gravestone.distance === Number.MAX_VALUE ? `Lokasjon: ${gravestone.location}` : `Avstand: ${(gravestone.distance / 1000).toFixed(2)} km`}
                    </p>
                  </div>
                </div>
              </div>

              {gravestone.visitStatusId !== 3 && (
                <button
                  className="w-full py-3 text-sm font-medium text-white bg-green-600 rounded-b-xl hover:bg-green-700 transition duration-300"
                  onClick={() => initiateVisit(gravestone.id, id)}
                >
                  Besøk
                </button>
              )}
            </div>
          ))}
      </div>
    )}

    {/* Modals */}
    <ImageModal
      isOpen={isModalOpen}
      onClose={closeModal}
      imageSrc={selectedImage}
    />
    <Modal isOpen={isMapModalOpen} close={toggleMapModal}>
      <GravestoneMapAll gravestones={workorder.gravestone_details} workorder_id={id} />
    </Modal>
  </div>
);
};

export default WorkOrderDetailsPage;
