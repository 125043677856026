import React, { useState, useEffect } from 'react';
import { useCustomer } from '../../context/CustomerContext';
import LoadingScreen from '../../components/LoadingScreen';
import { useNotifications } from '../../context/NotificationContext';
import axios from 'axios';
import {
  FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaGenderless, FaBirthdayCake, FaIdBadge, FaBuilding, FaClock, FaCommentDots, FaEdit, FaSave, FaTimes, FaCamera
} from 'react-icons/fa';

const CustomerProfile = () => {
  const { customer } = useCustomer();
  const { addNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [postNumberError, setPostNumberError] = useState('');
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchProfile = async () => {
      if (!customer) return;

      setIsLoading(true);
      setError('');

      try {
        const response = await axios.get(`${apiURL}/api/customers/${customer.id}`);
        setProfile(response.data);
        setFormData(response.data); // Initialize form data
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile');
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [customer, apiURL]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // If the field is customer_post_number, fetch the corresponding post location
    if (name === 'customer_post_number') {
      if (value.length === 4) {
        try {
          const response = await axios.get(`${apiURL}/api/postLocation/${parseInt(value)}`);
          console.log(response.data);
    
          if (response.data.length > 0) {
            const postLocation = response.data[0].Poststed; // Access the 'Poststed' from the first object in the array
            setFormData((prevData) => ({
              ...prevData,
              customer_post_location: postLocation,
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              customer_post_location: '',
            }));
            setPostNumberError('Ugyldig postnummer');
          }
        } catch (err) {
          setFormData((prevData) => ({
            ...prevData,
            customer_post_location: ''
          }));
          setPostNumberError('Ugyldig postnummer');
        }
      } else {
        setPostNumberError('');
        setFormData((prevData) => ({
          ...prevData,
          customer_post_location: ''
        }));
      }
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
    setFormData(profile); // Reset form data when toggling edit mode
  };

  const handleSave = async () => {
    try {
      await axios.put(`${apiURL}/api/customers/${customer.id}/profile`, formData);
      setProfile(formData); // Update the profile state with the new data
      addNotification({ type: 'success', message: 'Profilen er oppdatert!' });
      setIsEditing(false);
    } catch (err) {
      console.error('Error saving profile:', err);
      addNotification({ type: 'error', message: 'Kunne ikke oppdatere profilen.' });
    }
  };

  if (isLoading) return <LoadingScreen />;
  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="bg-white shadow-lg rounded-2xl p-8 max-w-6xl w-full border-t-4 border-green-grave mx-auto">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">
        Din Profil
      </h1>
      {profile && (
        <div className="space-y-8">
          {/* Profile Card */}
          <div className="bg-gray-50 p-6 rounded-xl shadow-md">
            <div className="flex items-center space-x-6">
              {/* Profile Picture */}
              <div className="relative">
                <img
                  src={profile.profilePicture || '/images/default_picture.jpg'}
                  alt="Profile"
                  className="h-32 w-32 rounded-full object-cover border-4 border-green-grave"
                />
                {isEditing && (
                  <button className="absolute bottom-0 right-0 bg-white p-2 rounded-full shadow-md hover:bg-gray-100">
                    <FaCamera className="text-gray-700" />
                  </button>
                )}
              </div>
              {/* Personal Info */}
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                    <FaUser className="mr-2 text-green-grave" />
                    Personlig informasjon
                  </h2>
                  {isEditing ? (
                    <div className="flex space-x-2">
                      <button
                        onClick={handleSave}
                        className="bg-green-grave hover:bg-green-600 text-white px-3 py-2 rounded-lg flex items-center"
                      >
                        <FaSave className="mr-1" /> Lagre
                      </button>
                      <button
                        onClick={toggleEditMode}
                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-2 rounded-lg flex items-center"
                      >
                        <FaTimes className="mr-1" /> Avbryt
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={toggleEditMode}
                      className="bg-orange-grave hover:bg-orange-600 text-white px-3 py-2 rounded-lg flex items-center"
                    >
                      <FaEdit className="mr-1" /> Rediger
                    </button>
                  )}
                </div>
                {isEditing ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                    {/* First Name */}
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Fornavn:
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Last Name */}
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Etternavn:
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Email */}
                    <div>
                      <label className="block text-gray-700 mb-1">
                        E-post:
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Phone Number */}
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Telefon:
                      </label>
                      <input
                        type="text"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Address */}
                    <div className="md:col-span-2">
                      <label className="block text-gray-700 mb-1">
                        Adresse:
                      </label>
                      <input
                        type="text"
                        name="customer_address"
                        value={formData.customer_address}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Post Number and Location */}
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Postnummer:
                      </label>
                      <input
                        type="text"
                        name="customer_post_number"
                        value={formData.customer_post_number}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Poststed:
                      </label>
                      <input
                        type="text"
                        name="customer_post_location"
                        value={formData.customer_post_location}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {postNumberError && (
                      <p className="text-red-500 md:col-span-2">
                        {postNumberError}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="mt-4 space-y-2 text-gray-700">
                    <p className="flex items-center">
                      <FaUser className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Navn:</span>{' '}
                      {profile.first_name} {profile.last_name}
                    </p>
                    <p className="flex items-center">
                      <FaEnvelope className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">E-post:</span>{' '}
                      {profile.email}
                    </p>
                    <p className="flex items-center">
                      <FaPhone className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Telefon:</span>{' '}
                      {profile.phone_number}
                    </p>
                    <p className="flex items-center">
                      <FaMapMarkerAlt className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Adresse:</span>
                    </p>
                    <p className="ml-8">
                      {profile.customer_address}
                      <br />
                      {profile.customer_post_number}{' '}
                      {profile.customer_post_location}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Customer Information Card */}
          <div className="bg-gray-50 p-6 rounded-xl shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
              <FaIdBadge className="mr-2 text-green-grave" /> Kundeinformasjon
            </h2>
            <div className="space-y-2 text-gray-700">
              <p className="flex items-center">
                <FaIdBadge className="mr-2 text-green-grave" />
                <span className="font-semibold mr-1">Kundenummer:</span>{' '}
                {profile.customer_number}
              </p>
              <p className="flex items-center">
                <FaBuilding className="mr-2 text-green-grave" />
                <span className="font-semibold mr-1">Org-nummer:</span>{' '}
                {profile.org_number || 'Ikke oppgitt'}
              </p>
              <p className="flex items-center">
                <FaBuilding className="mr-2 text-green-grave" />
                <span className="font-semibold mr-1">Firma:</span>{' '}
                {profile.is_company ? profile.company_name : 'Ikke et firma'}
              </p>
              <p className="flex items-center">
                <FaClock className="mr-2 text-green-grave" />
                <span className="font-semibold mr-1">Sist pålogget:</span>{' '}
                {profile.last_login
                  ? new Date(profile.last_login).toLocaleString('nb-NO')
                  : 'Aldri'}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerProfile;