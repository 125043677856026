import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useNotifications } from '../../context/NotificationContext';
import axios from 'axios';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { useLogs } from '../../hooks/useLogs';
import { useUser } from '../../context/UserContext';

const GravepersonForm = ({ formData, setFormData, index, onRemove }) => {
  // Handle input change for each field in the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Create a copy of formData and update the specific field for the current index
    const updatedForm = [...formData];
    updatedForm[index] = { ...updatedForm[index], [name]: value };
    setFormData(updatedForm);
  };

  // Format date strings to be compatible with HTML date input fields
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format
  };

  return (
    <div className="mb-8 p-4 border border-gray-200 rounded">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {/* First Name Field */}
        <div className="mb-4">
          <label htmlFor={`first_name-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Fornavn</label>
          <input type="text" id={`first_name-${index}`} name="first_name" value={formData[index].first_name || ''} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
        </div>
        {/* Last Name Field */}
        <div className="mb-4">
          <label htmlFor={`last_name-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Etternavn</label>
          <input type="text" id={`last_name-${index}`} name="last_name" value={formData[index].last_name || ''} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
        </div>
        {/* Birthdate Field */}
        <div className="mb-4">
          <label htmlFor={`birthdate-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Fødselsdato</label>
          <input 
            type="date" 
            id={`birthdate-${index}`} 
            name="birthdate" 
            value={formatDateForInput(formData[index].birthdate)} 
            onChange={handleChange} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
          />
        </div>
        {/* Deathdate Field */}
        <div className="mb-4">
          <label htmlFor={`deathdate-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Dødsdato</label>
          <input 
            type="date" 
            id={`deathdate-${index}`} 
            name="deathdate" 
            value={formatDateForInput(formData[index].deathdate)} 
            onChange={handleChange} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
          />
        </div>
        {/* Memorial Page URL Field */}
        <div className="mb-4">
          <label htmlFor={`minneside_url-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Minneside URL</label>
          <input 
            type="url" 
            id={`minneside_url-${index}`} 
            name="minneside_url" 
            value={formData[index].minneside_url || ''} 
            onChange={handleChange} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
          />
        </div>
        {/* Obituary URL Field */}
        <div className="mb-4">
          <label htmlFor={`dodsannonse_url-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Dødsannonse URL</label>
          <input 
            type="url" 
            id={`dodsannonse_url-${index}`} 
            name="dodsannonse_url" 
            value={formData[index].dodsannonse_url || ''} 
            onChange={handleChange} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
          />
        </div>
      </div>
      {/* Description Field */}
      <div className="mb-4">
        <label htmlFor={`description-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Beskrivelse</label>
        <textarea id={`description-${index}`} name="description" value={formData[index].description || ''} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
      </div>
      {/* Remove Button */}
      <div className="flex justify-end mt-4">
        <button type="button" onClick={() => onRemove(index)} className="flex items-center justify-center text-red-500 hover:text-red-600">
          <AiOutlineMinusCircle className="mr-2" /> Fjern
        </button>
      </div>
    </div>
  );
};

const AddGraveperson = () => {
  const { gravestoneId } = useParams(); // Get gravestoneId from URL parameters
  const navigate = useNavigate(); // Navigate programmatically after form submission
  const { addNotification } = useNotifications(); // Use notification context for displaying messages
  const { createLog } = useLogs(); // Use the useLogs hook to create logs
  const apiURL = process.env.REACT_APP_API_BASE_URL || ''; // Base API URL from environment variables
  const [formData, setFormData] = useState([{ // Initial state for form data
    first_name: '',
    last_name: '',
    description: '',
    birthdate: '',
    deathdate: '',
    minneside_url: '',
    dodsannonse_url: '',
  }]);
  const [gravepersonsToRemove, setGravepersonsToRemove] = useState([]); // Track gravepersons to be removed

  const { user } = useUser();

  useEffect(() => {
    const fetchGravepersons = async () => {
      try {
        // Fetch existing gravepersons for the current gravestone
        const response = await axios.get(`${apiURL}/api/gravepersons/gravestone/${gravestoneId}`);
        setFormData(response.data.length ? response.data : [{}]); // Populate formData or set an empty object if no data
      } catch (error) {
        console.error('Error fetching gravepersons:', error);
        addNotification({ message: 'Noe gikk galt med å hente data', type: 'error' }); // Notify user of error
        await createLog({ type: 'error', message: 'Feil ved henting av gravpersoner.', userId: user.id });
      }
    };

    if (gravestoneId) fetchGravepersons(); // Fetch data only if gravestoneId is available
  }, [gravestoneId]);

  // Format date strings to be compatible with database (YYYY-MM-DD format)
  const formatDateForDB = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format
  };

  // Add a new form for adding a graveperson
  const handleAddForm = async () => {
    setFormData([...formData, {
      first_name: '',
      last_name: '',
      description: '',
      birthdate: '',
      deathdate: '',
      minneside_url: '',
      dodsannonse_url: '',
    }]);
    await createLog({ type: 'action', message: `Ny gravpersonskjema lagt til for gravstein: ${gravestoneId}`, userId: user.id });
  };

  // Remove a form and track the graveperson to be removed
  const handleRemoveForm = async (index) => {
    const updatedForm = [...formData];
    const removedItem = updatedForm.splice(index, 1)[0]; // Remove the item and keep track of it
    setFormData(updatedForm);

    if (removedItem.id) {
      setGravepersonsToRemove([...gravepersonsToRemove, removedItem.id]); // Add removed item's ID to the list
      await createLog({ type: 'action', message: `Gravperson med ID ${removedItem.id} merket for fjerning på gravstein: ${gravestoneId}`, userId: user.id });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Remove gravepersons from the database
      for (let id of gravepersonsToRemove) {
        await axios.delete(`${apiURL}/api/gravepersons/${id}`);
        await createLog({ type: 'action', message: `Gravperson med ID ${id} fjernet for gravstein: ${gravestoneId}`, userId: user.id });
      }

      // Upsert gravepersons (insert or update)
      for (let data of formData) {
        const formattedData = {
          ...data,
          birthdate: formatDateForDB(data.birthdate),
          deathdate: formatDateForDB(data.deathdate),
        };
        await axios.post(`${apiURL}/api/gravepersons`, { ...formattedData, gravestone_id: gravestoneId });
        await createLog({ type: 'action', message: `Gravperson oppdatert eller lagt til: ${formattedData.first_name} ${formattedData.last_name} for gravstein: ${gravestoneId}.`, userId: user.id });
      }

      addNotification({ message: 'Gravpersoner oppdatert', type: 'success' }); // Notify user of success
      await createLog({ type: 'action', message: `Gravpersoner oppdatert for gravstein: ${gravestoneId}`, userId: user.id });
      navigate(`/gravsteiner/${gravestoneId}`); // Navigate back to the gravestone details page
    } catch (error) {
      console.error('Error adding graveperson:', error);
      addNotification({ message: 'Noe gikk galt', type: 'error' }); // Notify user of error
      await createLog({ type: 'error', message: `Feil ved oppdatering av gravpersoner for gravstein: ${gravestoneId}`, userId: user.id });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Administrer Gravpersoner</h1>
      {formData.map((_, index) => (
        <GravepersonForm key={index} index={index} formData={formData} setFormData={setFormData} onRemove={handleRemoveForm} />
      ))}
      {/* Button to add a new graveperson form */}
      <div className="flex justify-center items-center my-4">
        <hr className="border-1 border-green-300 flex-grow mr-2" />
        <AiOutlinePlusCircle className="text-4xl text-green-500 cursor-pointer hover:text-green-600 hover:scale-110 transition-transform duration-200" onClick={handleAddForm} />
        <hr className="border-1 border-green-300 flex-grow ml-2" />
      </div>
      {/* Form submission buttons */}
      <div className="text-right">
        <button onClick={handleSubmit} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">Lagre alle</button>
        <button onClick={() => setFormData([{}])} className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Tøm skjema</button>
      </div>
    </div>
  );
};

export default AddGraveperson;