import React, { useState, useEffect } from 'react';
import ReusableTable from '../../components/ReusableTable';
import Spinner from '../../components/Spinner';
import PageLayout from '../../layouts/PageLayout';
import ReusableHeaderV2 from '../../components/ReusableHeaderV2';
import { FaBuilding } from 'react-icons/fa';
import EditDepartmentModal from './EditDepartmentModal';
import CreateDepartmentModal from './CreateDepartmentModal'; // Import the CreateDepartmentModal

function Departments() {
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState('created_at');
  const [order, setOrder] = useState('DESC');
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const fetchDepartments = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiURL}/api/departments`);
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      const data = await response.json();
      setDepartments(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  const handleRowClick = (department) => {
    setSelectedDepartment(department);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedDepartment(null);
    fetchDepartments(); // Refresh departments data after editing
  };

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    fetchDepartments(); // Refresh departments data after creating
  };

  // Adjust columns as per your department data structure
  const columns = [
    {
      label: 'ID',
      accessor: 'id',
      width: '50px',
      onClick: handleRowClick,
    },
    {
      label: 'Navn',
      accessor: 'name',
      width: '150px',
      onClick: handleRowClick,
    },
    {
      label: 'Beskrivelse',
      accessor: 'description',
      width: '250px',
      onClick: handleRowClick,
    },
    {
      label: 'Aktiv?',
      accessor: 'active',
      type: 'boolean',
      width: '100px',
      onClick: handleRowClick,
    },
    {
      label: 'Opprettet',
      accessor: 'created_at',
      type: 'date-time',
      width: '200px',
      onClick: handleRowClick,
    },
    {
      label: 'Oppdatert',
      accessor: 'updated_at',
      type: 'date-time',
      width: '200px',
      onClick: handleRowClick,
    },
    // Add more columns as needed
  ];

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <PageLayout>
      <ReusableHeaderV2
        title="Avdelinger"
        onClick={handleCreateModalOpen} // Use onClick to open the create modal
        icon={FaBuilding}
      />
      {isLoading && <Spinner />}
      <ReusableTable
        columns={columns}
        data={departments}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
      />

      {/* Edit Modal */}
      {isEditModalOpen && selectedDepartment && (
        <EditDepartmentModal
          department={selectedDepartment}
          onClose={handleEditModalClose}
          onDepartmentUpdated={() => {
            handleEditModalClose();
          }}
        />
      )}

      {/* Create Modal */}
      {isCreateModalOpen && (
        <CreateDepartmentModal
          onClose={handleCreateModalClose}
          onDepartmentCreated={() => {
            handleCreateModalClose();
          }}
        />
      )}
    </PageLayout>
  );
}

export default Departments;