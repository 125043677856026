import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import classNames from 'classnames';

const SidebarProfile = ({ user, isOpen, profilePath }) => (
  <div className={classNames('p-2', { 'bg-gray-700': isOpen, 'bg-gray-800': !isOpen }, 'rounded-lg shadow', { 'p-1': !isOpen })}>
    <div className="flex items-center">
      <Link to={profilePath} className="text-white hover:text-green-grave transition duration-150 ease-in-out">
        <FaUserCircle className={isOpen ? "text-3xl" : "text-xl"} />
      </Link>
      {isOpen && (
        <Link to={profilePath} className="ml-2 flex-1 text-white hover:text-green-grave transition duration-150 ease-in-out">
          <div className="text-md font-medium truncate overflow-hidden whitespace-nowrap max-w-[150px]">
            {user?.name}
          </div>
          <div className="text-xs">{user?.type === "worker" ? "Arbeider" : "Admin"}</div>
        </Link>
      )}
    </div>
  </div>
);

export default SidebarProfile;