import React from 'react';
import { Link } from 'react-router-dom';

const StatBox = ({ href, bgColor, icon, count, label, children }) => (
  <Link
    to={href}
    className={`flex items-center justify-between shadow rounded-lg overflow-hidden ${bgColor} m-2 lg:m-1 p-4 hover:shadow-lg transition-shadow duration-300`}
  >
    <div className="flex items-center">
      {icon}
      <span className="ml-3 text-white font-medium">{label}</span>
    </div>
    {/* Render children if provided, otherwise show the count */}
    {children ? (
      <div className="mr-5">{children}</div>
    ) : (
      <span className="text-white text-2xl font-bold mr-5">{count}</span>
    )}
  </Link>
);

export default StatBox;