import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';

const EditGravestone = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [gravestone, setGravestone] = useState(null);
  const [editableGravestone, setEditableGravestone] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [graveyards, setGraveyards] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchGravestone = async () => {
      try {
        const response = await axiosInstance.get(`/api/gravestones/${id}`);
        const gravestoneData = response.data;
    
        // Ensure productDetails is an array of product IDs
        const productDetails = gravestoneData.productDetails
          ? gravestoneData.productDetails.map((product) => product.id)
          : [];
          
        setGravestone(gravestoneData);
        setEditableGravestone({ ...gravestoneData, productDetails });
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching gravestone data:', error);
      }
    };    

    const fetchGraveyards = async () => {
      try {
        const response = await axiosInstance.get('/api/graveyardsWorkorder');
        setGraveyards(response.data);
      } catch (error) {
        console.error('Error fetching graveyards data:', error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.get('/api/productsWorkorder');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products data:', error);
      }
    };

    fetchGravestone();
    fetchGraveyards();
    fetchProducts();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableGravestone({ ...editableGravestone, [name]: value });
  };

  const handleProductToggle = (productId) => {
    const currentProducts = editableGravestone.productDetails || [];
    const updatedProducts = currentProducts.includes(productId)
      ? currentProducts.filter(id => id !== productId)
      : [...currentProducts, productId];
    setEditableGravestone({ ...editableGravestone, productDetails: updatedProducts });
  };

  const handleSave = async () => {
    try {
      // Convert productDetails to JSON string
      const updatedGravestone = {
        ...editableGravestone,
        products: JSON.stringify(editableGravestone.productDetails),
      };
  
      await axiosInstance.put(`/api/gravestones/${id}`, updatedGravestone);
      navigate(`/gravsteiner/${id}`);
    } catch (error) {
      console.error('Error saving gravestone data:', error);
    }
  };

  const handleCancel  = () => {
    navigate(`/gravsteiner/${id}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-full mx-auto mt-10 px-0 lg:px-4">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden p-6">
        <h2 className="font-semibold text-2xl text-gray-800 mb-4">Rediger Gravstein</h2>
        <div className="space-y-4 text-lg">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
            <span className="font-bold text-gray-700 flex items-center">
              Plassering:
            </span>
            <input
              type="text"
              name="location"
              value={editableGravestone.location}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
            <span className="font-bold text-gray-700 flex items-center">
              Koordinater:
            </span>
            <input
              type="text"
              name="latitude"
              value={editableGravestone.latitude}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
            <input
              type="text"
              name="longitude"
              value={editableGravestone.longitude}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
            <span className="font-bold text-gray-700 flex items-center">
              Felt:
            </span>
            <input
              type="text"
              name="field"
              value={editableGravestone.field}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
            <span className="font-bold text-gray-700 flex items-center ml-4">
              Rad:
            </span>
            <input
              type="text"
              name="row"
              value={editableGravestone.row}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
            <span className="font-bold text-gray-700 flex items-center ml-4">
              Plass:
            </span>
            <input
              type="text"
              name="place"
              value={editableGravestone.place}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
            <span className="font-bold text-gray-700 flex items-center">
              Advarsel:
            </span>
            <input
              type="text"
              name="warning"
              value={editableGravestone.warning}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
            <span className="font-bold text-gray-700 flex items-center">
              Automatisk:
            </span>
            <select
              name="is_auto"
              value={editableGravestone.is_auto ? "Ja" : "Nei"}
              onChange={(e) => setEditableGravestone({ ...editableGravestone, is_auto: e.target.value === "Ja" })}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            >
              <option value="Ja">Ja</option>
              <option value="Nei">Nei</option>
            </select>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
            <span className="font-bold text-gray-700 flex items-center">
              Dager mellom besøk:
            </span>
            <input
              type="text"
              name="days_between_visits"
              value={editableGravestone.days_between_visits}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
            <span className="font-bold text-gray-700 flex items-center ml-4">
              Box:
            </span>
            <input
              type="text"
              name="box"
              value={editableGravestone.box}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
            <span className="font-bold text-gray-700 flex items-center">
              Kirkegård:
            </span>
            <select
              name="graveyard_id"
              value={editableGravestone.graveyard_id}
              onChange={handleInputChange}
              className="ml-2 text-gray-600 flex-grow bg-white p-2 rounded border border-gray-300"
            >
              {graveyards.map((graveyard) => (
                <option key={graveyard.id} value={graveyard.id}>
                  {graveyard.name}
                </option>
              ))}
            </select>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-start">
            <span className="font-bold text-gray-700 flex items-center">
              Produkter:
            </span>
            <div className="ml-2 flex flex-wrap gap-2">
              {products.map((product) => (
                <button
                key={product.id}
                onClick={() => handleProductToggle(product.id)}
                className={`px-2 py-1 rounded-full text-sm shadow-sm ${
                  editableGravestone.productDetails?.includes(product.id)
                    ? 'bg-green-grave text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
              >
                {product.name}
              </button>              
              ))}
            </div>
          </div>
          <button
            onClick={handleSave}
            className="bg-green-grave text-white px-4 py-2 rounded-full text-sm shadow-md hover:bg-green-600 transition"
          >
            Lagre
          </button>
          <button
            onClick={handleCancel}
            className="bg-red-500 text-white ml-2 px-4 py-2 rounded-full text-sm shadow-md hover:bg-red-800 transition"
          >
            Avbryt
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditGravestone;