import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import GeneralSettings from "./GeneralSettings";
import CustomerMessageSettings from "./CustomerMessageSettings";
import MapSettings from "./MapSettings";
import NotificationSettings from "./NotificationSettings";
import ContactSettings from "./ContactSettings";
import ScheduledJobs from "./ScheduledJobs";
import Products from "../products/Products";
import Activities from "../activities/Activities";
import ReusableHeaderV2 from "../../components/ReusableHeaderV2";
import PageLayout from "../../layouts/PageLayout";
import { FaCog, FaCommentAlt, FaMapMarkedAlt, FaBell, FaShoppingCart, FaTasks } from "react-icons/fa";

// Updated tab configuration with Norwegian-friendly keys
const tabConfig = [
  { key: "generelt", label: "Generelt", icon: FaCog, component: GeneralSettings },
  { key: "produkter", label: "Produkter", icon: FaShoppingCart, component: Products },
  { key: "aktiviteter", label: "Aktiviteter", icon: FaTasks, component: Activities },
  { key: "kundemelding", label: "Kundemelding", icon: FaCommentAlt, component: CustomerMessageSettings },
  { key: "kart", label: "Kart", icon: FaMapMarkedAlt, component: MapSettings },
  { key: "varsler", label: "Varsler", icon: FaBell, component: NotificationSettings },
  { key: "kontakt", label: "Kontakt", icon: FaCommentAlt, component: ContactSettings },
  { key: "planlagte-jobber", label: "Planlagte jobber", icon: FaCog, component: ScheduledJobs },
];

const Settings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeTab = searchParams.get("tab") || "generelt";

  useEffect(() => {
    // Default to "generelt" if no tab is specified in the URL
    if (!tabConfig.find((tab) => tab.key === activeTab)) {
      navigate("/innstillinger?tab=generelt", { replace: true });
    }
  }, [activeTab, navigate]);

  const handleTabChange = (tabKey) => {
    // Update the query parameter when a new tab is selected
    setSearchParams({ tab: tabKey });
  };

  const renderTabContent = () => {
    const activeTabConfig = tabConfig.find((tab) => tab.key === activeTab);
    if (activeTabConfig) {
      const ActiveComponent = activeTabConfig.component;
      return <ActiveComponent />;
    }
    return <GeneralSettings />;
  };

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Innstillinger" icon={FaCog} />
      <div className="tabs flex space-x-4 px-6">
        {tabConfig.map((tab) => (
          <button
            key={tab.key}
            onClick={() => handleTabChange(tab.key)}
            className={`tab ${
              activeTab === tab.key
                ? "bg-green-grave text-white"
                : "bg-gray-200 text-gray-700"
            } flex items-center space-x-2 p-2 rounded-lg transition-all duration-300`}
          >
            <tab.icon className="text-xl" />
            <span className="text-lg">{tab.label}</span>
          </button>
        ))}
      </div>
      <div className="tab-content flex-1 p-8 bg-white shadow-md m-6 rounded-lg">
        {renderTabContent()}
      </div>
    </PageLayout>
  );
};

export default Settings;