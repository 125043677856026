// NoDataTable.js
import React from 'react';
import { FaInbox } from 'react-icons/fa';

const NoDataTable = ({ message }) => (
    <tr>
      <td colSpan="100%" className="px-6 py-12 text-center text-gray-500">
        <FaInbox className="mx-auto mb-4 text-6xl text-gray-300" />
        <p className="text-xl font-semibold">{message || 'Ingen data å vise.'}</p>
        <p className="text-gray-400">Det ser ut som det ikke er noe data her.</p>
      </td>
    </tr>
);

export default NoDataTable;