import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ReusableTable from "../../components/ReusableTable";
import Spinner from "../../components/Spinner";
import ReusableHeaderV2 from "../../components/ReusableHeaderV2";
import PageLayout from "../../layouts/PageLayout";
import { FaPaintBrush } from 'react-icons/fa';

// Tab configuration for Templates
const tabConfig = [
  { key: "sms", label: "SMS Maler" },
  { key: "epost", label: "E-post Maler" },
];

function Templates() {
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [title, setTitle] = useState("Maler");
  const [newPath, setNewPath] = useState("/maler/ny");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("DESC");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeTab = searchParams.get("tab") || "sms"; // Default to 'sms' tab

  useEffect(() => {
    const fetchTemplates = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiURL}/api/templates/getAll`);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        setSmsTemplates(data.sms);
        setEmailTemplates(data.email);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    if (activeTab === "sms") {
      setTitle("SMS Maler");
      setNewPath("/maler/ny/sms");
    } else {
      setTitle("E-post Maler");
      setNewPath("/maler/ny/epost");
    }
  }, [activeTab]);

  const handleTabChange = (tabKey) => {
    // Update the query parameter when a new tab is selected
    setSearchParams({ tab: tabKey });
  };

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  // Columns for SMS and Email templates
  const smsColumns = [
    { label: "ID", accessor: "id", width: "50px" },
    { label: "Navn", accessor: "name", link: (row) => `/maler/${row.id}/sms`, width: "150px" },
    { label: "Innhold", accessor: "content", width: "250px" },
    { label: "Standard?", accessor: "is_default", type: "boolean", width: "100px" },
    { label: "Laget", accessor: "created_at", type: "date-time", width: "200px" },
    { label: "Oppdatert", accessor: "updated_at", type: "date-time", width: "200px" },
  ];

  const emailColumns = [
    { label: "ID", accessor: "id", width: "50px" },
    { label: "Navn", accessor: "name", link: (row) => `/maler/${row.id}/epost`, width: "150px" },
    { label: "Innhold", accessor: "content", type: "html", width: "250px" },
    { label: "Standard?", accessor: "is_default", type: "boolean", width: "100px" },
    { label: "Laget", accessor: "created_at", type: "date-time", width: "200px" },
    { label: "Oppdatert", accessor: "updated_at", type: "date-time", width: "200px" },
  ];

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <PageLayout>
      <ReusableHeaderV2
        title={title}
        linkTo={newPath}
        icon={FaPaintBrush}
        />

      <div className="tabs flex space-x-4 px-6">
        {tabConfig.map((tab) => (
          <button
            key={tab.key}
            onClick={() => handleTabChange(tab.key)}
            className={`tab ${
              activeTab === tab.key
                ? "bg-green-grave text-white"
                : "bg-gray-200 text-gray-700"
            } flex items-center space-x-2 p-2 rounded-lg transition-all duration-300`}
          >
            <span className="text-lg">{tab.label}</span>
          </button>
        ))}
      </div>

      <div className="tab-content flex-1 p-8 bg-white shadow-md m-6 rounded-lg">
        {isLoading && <Spinner />}
        {activeTab === "sms" && (
          <ReusableTable
            columns={smsColumns}
            data={smsTemplates}
            sort={{ field: sort, order: order }}
            onSort={handleSort}
          />
        )}
        {activeTab === "epost" && (
          <ReusableTable
            columns={emailColumns}
            data={emailTemplates}
            sort={{ field: sort, order: order }}
            onSort={handleSort}
          />
        )}
      </div>
    </PageLayout>
  );
}

export default Templates;