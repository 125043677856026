import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FaRegCalendarAlt, FaTools } from 'react-icons/fa';
import Rating from '../../components/Rating';
import VisitComment from '../../components/VisitComment';
import { useUser } from '../../context/UserContext';

const VisitDetailPage = () => {
  const { visitId, isLegacy } = useParams();
  const [visitDetails, setVisitDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); // State for full-size image
  const [zoomPosition, setZoomPosition] = useState(null); // State for zoom effect

  const { user } = useUser();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';
  const baseURL = process.env.REACT_APP_MAIN_URL || '';
  
  // Create refs for the images
  const afterImageRef = useRef(null);
  const beforeImageRef = useRef(null);
  const modalImageRef = useRef(null); // Ref for the modal image

  useEffect(() => {
    const fetchVisitDetails = async () => {
      setLoading(true);
      try {
        const endpoint = isLegacy === '1' ? `${apiURL}/api/legacy-visits/${visitId}` : `${apiURL}/api/visits/${visitId}`;
        const response = await fetch(endpoint);
        if (!response.ok) throw new Error('Failed to fetch visit details');
        const data = await response.json();
        setVisitDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVisitDetails();
  }, [visitId, isLegacy, apiURL]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const visitDate = isLegacy === '1' ? visitDetails.visit_date : visitDetails.visitDetails?.visit_date;
  const description = isLegacy === '1' ? visitDetails.description : visitDetails.visitDetails?.description;
  const afterPicture = isLegacy === '1' ? visitDetails.after_pictures : visitDetails.visitDetails?.after_picture_url;
  const beforePicture = isLegacy === '1' ? visitDetails.before_pictures : visitDetails.visitDetails?.before_picture_url;
  const activities = isLegacy === '1' ? visitDetails.activities : visitDetails.selectedActivities?.map((activity) => activity.name);
  const workerName = visitDetails.worker_name;

  // Open image in full-size
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // Close full-size image modal
  const closeImageModal = () => {
    setSelectedImage(null);
    setZoomPosition(null); // Reset zoom position when closing the modal
  };

  // Handle zoom effect in the modal image
  const handleMouseMove = (e) => {
    const { left, top, width, height } = modalImageRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
  
    setZoomPosition({ x: e.clientX, y: e.clientY, bgX: x, bgY: y });
  };

  const handleMouseLeave = () => {
    setZoomPosition(null);
  };

  return (
    <div className="visit-detail-page py-8">
      <div className="max-w-full mx-auto bg-white shadow overflow-hidden sm:rounded-lg grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Left Side: Visit Details */}
        <div className="bg-white p-6">
          <div className="px-4 py-5 sm:px-6 border-b">
            <h1 className="text-lg leading-6 font-medium text-gray-900">{isLegacy === '1' ? 'Legacy Besøksdetaljer' : 'Besøksdetaljer'}</h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">{isLegacy === '1' ? 'Informasjon fra et tidligere besøk' : 'Informasjon fra besøket'}</p>
          </div>
          {visitDetails && (
            <>
              <div className="bg-gray-50 px-4 py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 sm:mb-4">Bilder</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  <div className="flex flex-wrap -mx-2 overflow-hidden">
                    {/* After Picture */}
                    <div className="my-2 px-2 w-full overflow-hidden sm:w-1/2 relative">
                      <img
                        src={(isLegacy === '1' ? baseURL : apiURL) + (afterPicture || "/images/default_after.jpg")}
                        alt="After the visit"
                        ref={afterImageRef}
                        className="object-cover rounded-lg shadow-lg mx-auto cursor-pointer"
                        style={{ maxHeight: "300px" }}
                        onClick={() => handleImageClick((isLegacy === '1' ? baseURL : apiURL) + (afterPicture || "/images/default_after.jpg"))}
                      />
                      <p className="text-center text-sm mt-2">Etter</p>
                    </div>

                    {/* Before Picture */}
                    <div className="my-2 px-2 w-full overflow-hidden sm:w-1/2 relative">
                      <img
                        src={(isLegacy === '1' ? baseURL : apiURL) + (beforePicture || "/images/default_before.jpg")}
                        alt="Before the visit"
                        ref={beforeImageRef}
                        className="object-cover rounded-lg shadow-lg mx-auto cursor-pointer"
                        style={{ maxHeight: "300px" }}
                        onClick={() => handleImageClick((isLegacy === '1' ? baseURL : apiURL) + (beforePicture || "/images/default_before.jpg"))}
                      />
                      <p className="text-center text-sm mt-2">Før</p>
                    </div>
                  </div>
                </dd>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Beskrivelse</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{description || 'Ingen beskrivelse tilgjengelig'}</dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Besøksdato</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <FaRegCalendarAlt className="inline mr-2 text-blue-500" />
                      {new Date(visitDate).toLocaleDateString("nb-NO")}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Dette ble gjort</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <FaTools className="inline mr-2 text-green-600" />
                      {activities?.join(', ') || 'Ingen aktiviteter registrert'}
                    </dd>
                  </div>
                  {isLegacy === '1' && workerName && (
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Utført av</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{workerName || 'Ingen arbeider tilgjengelig'}</dd>
                    </div>
                  )}
                  {/* Add Rating Component */}
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Vurdering</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <Rating
                        visit_type={isLegacy === '1' ? 'visits' : 'visits_new'}
                        visit_id={visitId}
                        gravestone_id={visitDetails.gravestone_id}
                        customer_id={visitDetails.customer_id}
                        readOnly={true}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </>
          )}
        </div>

        {/* Right Side: Comments */}
        <div className="bg-white p-6 border-l">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Kommentarer</h2>
          <VisitComment
            visit_id={visitId}
            visit_type={isLegacy === '1' ? 'visits' : 'visits_new'}
            gravestone_id={visitDetails.gravestone_id}
            user_id={user.id}
            readOnly={false}
          />
        </div>
      </div>

      {/* Modal for full-size image */}
      {selectedImage && (
  <div
    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
    onClick={closeImageModal}
  >
    <div className="relative">
      <img
        src={selectedImage}
        alt="Full-size"
        className="max-w-screen max-h-screen object-contain rounded-lg shadow-lg cursor-none"
        ref={modalImageRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: 'none' }} // Ensures the cursor is hidden
      />
      {zoomPosition && (
        <div
          className="absolute pointer-events-none"
          style={{
            width: "400px",
            height: "400px",
            backgroundImage: `url(${selectedImage})`,
            backgroundPosition: `${zoomPosition.bgX}% ${zoomPosition.bgY}%`,
            backgroundSize: "600%",
            border: "1px solid #fff",
            top: `${zoomPosition.y - 200}px`,
            left: `${zoomPosition.x - 800}px`,
            zIndex: 10,
          }}
        />
      )}
      <button
        className="absolute top-2 right-2 text-white text-xl font-bold"
        onClick={closeImageModal}
      >
        &times;
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export default VisitDetailPage;