// components/Toolbar.js
import React from 'react';

const Toolbar = ({ children }) => {
  return (
    <div className="flex flex-wrap justify-end items-center bg-gray-100 p-2 shadow-md space-x-4">
      {children}
    </div>
  );
};

export default Toolbar;