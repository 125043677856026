import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { FaStar } from 'react-icons/fa';

const StaticRating = ({ visit_type, visit_id, gravestone_id, customer_id }) => {
  const [rating, setRating] = useState(0);
  const [count, setCount] = useState(0);
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    fetchRating();
  }, [customer_id, gravestone_id, visit_type, visit_id]);

  const fetchRating = async () => {
    try {
      const response = await axiosInstance.get(`/api/stats/average-rating`, {
        params: {  }, // Assuming these are used for fetching the rating
      });
      if (response.data && response.data.avg_rating) {
        setRating(response.data.avg_rating);
        setCount(response.data.rating_count);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setRating(0); // No rating found, initialize with 0
      } else {
        console.error('Error fetching rating:', error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex">
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;
          const fillPercentage = Math.min(Math.max(rating - index, 0), 1) * 100; // Calculate the fill percentage for each star

          return (
            <div
              key={index}
              className="relative"
              style={{ width: 24, height: 24, display: 'inline-block' }}
            >
              <FaStar className="text-gray-300" size={24} /> {/* Empty star */}
              <div
                className="absolute top-0 left-0 overflow-hidden"
                style={{ width: `${fillPercentage}%` }}
              >
                <FaStar className="text-yellow-500" size={24} /> {/* Filled part */}
              </div>
            </div>
          );
        })}
      </div>
      <span className="text-xs font-bold text-gray-200">{rating} / 5</span> {/* Display rating value below stars */}
        <span className="text-xs italic text-gray-200">({count} vurderinger)</span> {/* Display rating count below rating value      */}
    </div>
  );
};

export default StaticRating;