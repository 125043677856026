// src/components/EditGraveyard.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';

const EditGraveyard = () => {
  const { id } = useParams();

  // State variables
  const [formData, setFormData] = useState({
    name: '',
    fellesraad_id: '',
    fellesraad: '',
    butikk_id: '',
    description: '',
    picture: null,
    picture_url: '',
    location: '',
    latitude: '',
    longitude: '',
    graveyard_address: '', // New field
    graveyard_zip: '',
    graveyard_city: '',
    kommune: '',
    contact: '',
    contact_phone: '',
    contact_email: '',
    eclesia_url: '',
    graves: '',
    active: true,
    plantasjen_butikk_id: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchGraveyard = async () => {
      try {
        const response = await axiosInstance.get(`/api/graveyards/${id}`);
        setFormData({
          ...response.data,
          active: response.data.active === 1,
          picture: null,
          picture_url: response.data.picture, // Store current picture URL
        });
        setLoading(false);
      } catch (err) {
        console.error('Error fetching graveyard:', err);
        setError('Failed to fetch graveyard data.');
        setLoading(false);
      }
    };

    fetchGraveyard();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      picture: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = new FormData();

    // Append form fields to FormData
    for (const key in formData) {
      if (formData[key] !== null && formData[key] !== undefined) {
        if (key === 'active') {
          updatedData.append(key, formData[key] ? 'true' : 'false');
        } else if (key === 'picture' && formData.picture) {
          updatedData.append(key, formData.picture);
        } else {
          updatedData.append(key, formData[key]);
        }
      }
    }

    try {
      await axiosInstance.put(`/api/graveyards/${id}`, updatedData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Graveyard updated successfully!');
    } catch (err) {
      console.error('Error updating graveyard:', err);
      alert('Failed to update graveyard.');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Rediger gravlund</h1>
      {loading ? (
        <LoadingScreen />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Navn */}
          <div>
            <label className="block text-gray-700">Navn</label>
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
              required
            />
          </div>
  
          {/* Fellesråd ID */}
          <div>
            <label className="block text-gray-700">Fellesråd ID</label>
            <input
              type="number"
              name="fellesraad_id"
              value={formData.fellesraad_id || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Fellesråd */}
          <div>
            <label className="block text-gray-700">Fellesråd</label>
            <input
              type="text"
              name="fellesraad"
              value={formData.fellesraad || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Kommune */}
          <div>
            <label className="block text-gray-700">Kommune</label>
            <input
              type="text"
              name="kommune"
              value={formData.kommune || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Butikk ID */}
          <div>
            <label className="block text-gray-700">Butikk ID</label>
            <input
              type="number"
              name="butikk_id"
              value={formData.butikk_id || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Beskrivelse */}
          <div>
            <label className="block text-gray-700">Beskrivelse</label>
            <textarea
              name="description"
              value={formData.description || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            ></textarea>
          </div>
  
          {/* Bilde */}
          <div>
            <label className="block text-gray-700">Bilde</label>
            <input
              type="file"
              name="picture"
              onChange={handleFileChange}
              className="w-full"
              accept="image/*"
            />
            {formData.picture_url && (
              <img
                src={formData.picture_url}
                alt="Nåværende"
                className="mt-2 h-32"
              />
            )}
          </div>
  
          {/* Sted */}
          <div>
            <label className="block text-gray-700">Sted</label>
            <input
              type="text"
              name="location"
              value={formData.location || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Breddegrad */}
          <div>
            <label className="block text-gray-700">Breddegrad</label>
            <input
              type="number"
              name="latitude"
              value={formData.latitude || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
              step="any"
            />
          </div>
  
          {/* Lengdegrad */}
          <div>
            <label className="block text-gray-700">Lengdegrad</label>
            <input
              type="number"
              name="longitude"
              value={formData.longitude || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
              step="any"
            />
          </div>
  
          {/* Gravlundens adresse */}
          <div>
            <label className="block text-gray-700">Gravlundens adresse</label>
            <input
              type="text"
              name="graveyard_address"
              value={formData.graveyard_address || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Gravlundens postnummer */}
          <div>
            <label className="block text-gray-700">Gravlundens postnummer</label>
            <input
              type="number"
              name="graveyard_zip"
              value={formData.graveyard_zip || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Gravlundens by */}
          <div>
            <label className="block text-gray-700">Gravlundens by</label>
            <input
              type="text"
              name="graveyard_city"
              value={formData.graveyard_city || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Kontaktperson */}
          <div>
            <label className="block text-gray-700">Kontaktperson</label>
            <input
              type="text"
              name="contact"
              value={formData.contact || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Kontakttelefon */}
          <div>
            <label className="block text-gray-700">Kontakttelefon</label>
            <input
              type="text"
              name="contact_phone"
              value={formData.contact_phone || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Kontakt e-post */}
          <div>
            <label className="block text-gray-700">Kontakt e-post</label>
            <input
              type="email"
              name="contact_email"
              value={formData.contact_email || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Eclesia URL */}
          <div>
            <label className="block text-gray-700">Eclesia URL</label>
            <input
              type="url"
              name="eclesia_url"
              value={formData.eclesia_url || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Graver */}
          <div>
            <label className="block text-gray-700">Graver</label>
            <input
              type="number"
              name="graves"
              value={formData.graves || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Aktiv */}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="active"
              checked={formData.active}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label className="text-gray-700">Aktiv</label>
          </div>
  
          {/* Plantasjen Butikk ID */}
          <div>
            <label className="block text-gray-700">Plantasjen Butikk ID</label>
            <input
              type="number"
              name="plantasjen_butikk_id"
              value={formData.plantasjen_butikk_id || ''}
              onChange={handleInputChange}
              className="w-full border p-2 rounded"
            />
          </div>
  
          {/* Lagre endringer */}
          <button
            type="submit"
            className="bg-green-grave text-white px-4 py-2 rounded hover:bg-orange-grave transition"
          >
            Lagre endringer
          </button>
        </form>
      )}
    </div>
  );  
};

export default EditGraveyard;