// src/components/Worklogs.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance';
import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { startOfDay, endOfDay } from 'date-fns';
import ReactModal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './worklog.css';

import LoadingScreen from '../../components/LoadingScreen';

const Worklogs = () => {
  const [users, setUsers] = useState([]);
  const [items, setItems] = useState([]); // Worklogs formatted for the timeline
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [dateRange, setDateRange] = useState({
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch active users
        const responseUsers = await axiosInstance.get(`/api/users`, {
          params: {
            active: true,
            limit: 1000,
          },
        });
        const activeUsers = responseUsers.data.users;

        // Fetch worklogs for active users within date range
        const userIds = activeUsers.map((user) => user.id).join(',');

        const responseWorklogs = await axiosInstance.get(`/api/worklogs`, {
          params: {
            user_ids: userIds,
          },
        });

        // Format data for react-calendar-timeline
        const groups = activeUsers.map((user) => ({
          id: user.id,
          title: user.name,
        }));

        const items = responseWorklogs.data.worklogs.map((worklog) => {
          const isCompleted = !!worklog.end_time;
          return {
            id: worklog.id,
            group: worklog.user_id,
            title: '',
            start_time: new Date(worklog.start_time),
            end_time: isCompleted ? new Date(worklog.end_time) : new Date(),
            className: isCompleted ? 'worklog-completed' : 'worklog-in-progress',
            login_comment: worklog.login_comment,
            logout_comment: worklog.logout_comment,
            itemProps: {
              'data-tip': `Start: ${new Date(worklog.start_time).toLocaleString(
                'nb-NO'
              )}\nEnd: ${
                worklog.end_time
                  ? new Date(worklog.end_time).toLocaleString('nb-NO')
                  : 'Pågår'
              }`,
            },
          };
        });

        setUsers(groups);
        setItems(items);
      } catch (err) {
        console.error(err);
        setError('Kunne ikke hente data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiURL, dateRange]);

  const handleItemClick = (itemId) => {
    // Find the selected worklog
    const worklog = items.find((item) => item.id === itemId);
    setSelectedItem(worklog);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
  };

  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const user = users.find((u) => u.id === item.group);

    return (
      <div
        {...getItemProps({
          className: `custom-item ${item.className} ${
            itemContext.selected ? 'selected' : ''
          }`,
          style: {
            borderRadius: '8px',
            borderWidth: itemContext.selected ? '2px' : '1px',
            borderColor: itemContext.selected
              ? '#4A90E2'
              : itemContext.borderColor,
          },
        })}
        title={`${user.title}\nStart: ${item.start_time.toLocaleString(
          'nb-NO'
        )}\nEnd: ${item.end_time.toLocaleString('nb-NO')}`}
      >
        {itemContext.useResizeHandle ? (
          <div {...leftResizeProps} className="custom-resize-handle left-handle" />
        ) : null}

        <div className="rct-item-content custom-item-content">
          <span className="worklog-info">
            {item.start_time.toLocaleTimeString('nb-NO')} -{' '}
            {item.end_time.toLocaleTimeString('nb-NO')}
          </span>
        </div>

        {itemContext.useResizeHandle ? (
          <div {...rightResizeProps} className="custom-resize-handle right-handle" />
        ) : null}
      </div>
    );
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div>Feil: {error}</div>;

  return (
    <div className="container">
      <h1 className="text-2xl font-bold mb-4 pl-4 pt-4">Arbeidstidslinje</h1>

      {/* Timeline Component */}
      <Timeline
        groups={users}
        items={items}
        defaultTimeStart={dateRange.from}
        defaultTimeEnd={dateRange.to}
        canMove={true}
        canResize="both"
        stackItems
        lineHeight={60}
        itemHeightRatio={0.8}
        onItemSelect={handleItemClick}
        onItemMove=''
        onItemResize=''
        itemRenderer={itemRenderer}
        className="custom-timeline"
      />

      {/* Modal for Worklog Details */}
      <ReactModal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="Arbeidstidsdetaljer"
  ariaHideApp={false}
  className="modal"
  overlayClassName="modal-overlay"
>
  {selectedItem && (
    <div>
      <h2 className="text-xl font-bold mb-4">Arbeidstidsdetaljer</h2>
      <p>
        <strong>Bruker:</strong>{' '}
        {users.find((u) => u.id === selectedItem.group).title}
      </p>
      <p>
        <strong>Starttid:</strong>{' '}
        {selectedItem.start_time.toLocaleString('nb-NO')}
      </p>
      <p>
        <strong>Sluttid:</strong>{' '}
        {selectedItem.end_time.toLocaleString('nb-NO')}
      </p>
      {selectedItem.login_comment && (
        <p>
          <strong>Innloggingskommentar:</strong> {selectedItem.login_comment}
        </p>
      )}
      {selectedItem.logout_comment && (
        <p>
          <strong>Utloggingskommentar:</strong> {selectedItem.logout_comment}
        </p>
      )}
      <button
        onClick={closeModal}
        className="mt-4 bg-green-grave text-white px-4 py-2 rounded hover:bg-orange-grave transition"
      >
        Lukk
      </button>
    </div>
  )}
</ReactModal>
    </div>
  );
};

export default Worklogs;