import React from 'react';
import { Link } from 'react-router-dom';

const ReusableHeaderV2 = ({ title, linkTo, linkText, onClick, alignment = 'left', icon: Icon, children }) => {
  const alignmentClasses = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  };

  // Determine if we should render a Link, a button, or nothing
  const renderActionButton = () => {
    if (linkTo) {
      return (
        <Link to={linkTo} className="ml-4 flex items-center">
          <span className="text-lg bg-gray-800 text-white px-3 py-1 hover:bg-green-grave cursor-pointer rounded">
            {linkText || '+'}
          </span>
        </Link>
      );
    } else if (onClick) {
      return (
        <button
          onClick={onClick}
          className="ml-4 flex items-center text-lg bg-gray-800 text-white px-3 py-1 hover:bg-green-grave cursor-pointer rounded"
        >
          {linkText || '+'}
        </button>
      );
    }
    // If neither linkTo nor onClick is provided, render nothing
    return null;
  };

  return (
    <div className={`bg-white shadow-md p-4 mb-4 ${alignmentClasses[alignment]} flex items-center justify-between`}>
      {/* Left section: Title, Icon, and Action Button */}
      <div className="flex items-center">
        {Icon && <Icon className="mr-3 text-4xl" />}
        <h1 className="text-4xl font-bold">{title}</h1>
        {renderActionButton()}
      </div>

      {/* Right section: Optional children */}
      {children && <div className="ml-auto">{children}</div>}
    </div>
  );
};

export default ReusableHeaderV2;